import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit, type) => {
  // Currently Triplelift is not able to build video bids
  if (type === 'banner') {
    const currentSite = config.triplelift[config.site];
    const { adaptor } = config.triplelift;
    let placement = '';

    // Check if placement is defined
    if (typeof config.triplelift[config.site].formats[config.device] !== 'undefined') {
      placement = config.triplelift[config.site].formats[config.device].indexOf(name);
    } else {
      placement = -1;
    }

    if (typeof currentSite !== 'undefined' && placement > -1) {
      const inventoryCode = config.triplelift[config.site].inventoryCode + name;

      adUnit.bids.push({
        bidder: adaptor,
        params: {
          inventoryCode,
        },
      });
    }
  }
};
