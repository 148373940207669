export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/34ad46cf-e1b1-4bf2-ab08-a453f81497d3',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  formats: {
    banniere_milieu: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  // Same as lobs
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lobs.json',
  smartAdCallConfig: {},
  appnexus: {
    teleobs: {
      publisherId: 1131429,
      banniere_basse: { placementId: 24923839 },
      banniere_haute: { placementId: 24923838 },
      pave_haut: { placementId: 24923841 },
      pave_milieu: { placementId: 24923842 },
      inread: { placementId: 24923843 },
      inread_top: { placementId: 26485429 },
    },
  },
  indexExchange: {
    teleobs: {
      formats: {
        desktop: {
          banniere_haute: '766732',
          pave_haut: '766729',
          inread: '766731',
          inread_top: '766731',
          banniere_basse: '766730',
          pave_milieu: '766733',
        },
        tablet: {
          banniere_haute: '766732',
          pave_haut: '766729',
          inread: '766731',
          inread_top: '766731',
          banniere_basse: '766730',
          pave_milieu: '766733',
        },
        mobile: {
          banniere_haute: '766732',
          inread: '767784',
          inread_top: '767784',
        },
      },
    },
  },
  // Same as lobs
  invibes: {
    placementId: 'invibes_nouvelobsprogrammetv',
    placementIdInreadTop: 'invibes_nouvelobsprogrammetv_top',
  },
  justpremium: {
    teleobs: {
      banniere_haute: { zone: 151324 },
    },
  },
  mediasquare: {
    teleobs: {
      formats: {
        banniere_haute: { code: 'programme-tv.nouvelobs.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'programme-tv.nouvelobs.com_rg_atf_pave_desktop' },
        inread_top: { code: 'programme-tv.nouvelobs.com_inread-top_atf_pave_desktop' },
        inread: { code: 'programme-tv.nouvelobs.com_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'programme-tv.nouvelobs.com_rg_btf_mban_desktop' },
        pave_milieu: { code: 'programme-tv.nouvelobs.com_rg_mtf_pave_desktop' },
      },
    },
  },
  outbrain: {
    teleobs: {
      formats: {
        banniere_haute: 'ATF-3/Obs_ProgTV_banniere_haute',
        pave_haut: 'ATF-3/Obs_ProgTV_pave_haut',
        inread_top: 'ATF-2/Obs_ProgTV_inread_top',
        inread: 'ATF-2/Obs_ProgTV_inread',
        pave_milieu: 'ATF-3/Obs_ProgTV_pave_milieu',
        banniere_basse: 'ATF-3/Obs_ProgTV_banniere_basse',
      },
    },
  },
  smart: {
    teleobs: {
      siteId: 502057,
      pageId: 1567065,
    },
  },
  sublime: {
    teleobs: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 36507 },
        },
        mobile: {
          cover: { zoneId: 36508 },
        },
      },
    },
  },
  teads: {
    teleobs: {
      formats: {
        banniere_haute: { placementId: 178773, pageId: 164036 },
        pave_haut: { placementId: 178774, pageId: 164037 },
        pave_milieu: { placementId: 178775, pageId: 164038 },
        inread_top: { placementId: 152304, pageId: 138814 },
        inread: { placementId: 152304, pageId: 138814 },
        banniere_basse: { placementId: 178776, pageId: 164039 },
      },
    },
  },
  triplelift: {
    teleobs: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'ProgTV_HB_',
    },
  },
};
