export default {
  slots: '.dfp-slot',
  // Specific to Radio France
  pubstack: '//boot.pbstck.com/v1/tag/66c2cdd6-c53c-4fea-951b-4a907ce6724d',
  refresh: {
    excludedFormats: [],
  },
  // Specific to Radio France
  appnexus: {
    radiofrance: {
      publisherId: 2089060,
      banniere_haute: { placementId: 24953952 },
      pave_haut: { placementId: 24954081 },
      pave_bas: { placementId: 24954196 },
      inread: { placementId: 24954257 },
      inread_top: { placementId: 24954257 },
    },
  },
  // Specific to Radio France
  indexExchange: {
    radiofrance: {
      formats: {
        desktop: {
          banniere_haute: '808986',
          pave_haut: '808987',
          inread: '808989',
          inread_top: '808989',
          pave_bas: '808985',
        },
        tablet: {
          banniere_haute: '808986',
          pave_haut: '808987',
          inread: '808989',
          inread_top: '808989',
          pave_bas: '808985',
        },
        mobile: {
          banniere_haute: '809011',
          inread: '809012',
          inread_top: '809012',
        },
      },
    },
  },
  // Specific to Radio France
  invibes: {
    placementId: 'invibes_radiofrance',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to Radio France
  mediasquare: {
    radiofrance: {
      formats: {
        banniere_haute: { code: 'radiofrance.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'radiofrance.fr_rg_atf_pave_desktop' },
        inread: { code: 'radiofrance.fr_inread_mtf_pave_desktop' },
        pave_bas: { code: 'radiofrance.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to Radio France
  smart: {
    radiofrance: {
      siteId: 497682,
      pageId: 1554990,
    },
  },
  sublime: {
    radiofrance: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to Radio France
  teads: {
    radiofrance: {
      formats: {
        inread_top: { placementId: 159814, pageId: 145846 },
        inread: { placementId: 159814, pageId: 145846 },
      },
    },
  },
  // Specific to Radio France
  triplelift: {
    radiofrance: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'RadioFrance_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
