import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  const { siteId, pageId } = config.smart[config.site];
  const { adaptor } = config.smart;
  const placement = config.smart.formats[name];

  if (
    typeof siteId !== 'undefined'
    && typeof placement !== 'undefined'
  ) {
    const { formatId } = config.smart.formats[name];

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        siteId,
        pageId,
        formatId,
      },
    });
  }
};
