/* eslint-disable import/no-cycle */
/* global window */
/* eslint-disable no-mixed-operators */
import { config } from '../../wrapperConfig';
import { loadScript, log } from '../utils';

window.sublime = window.sublime || {};
window.sublime.notifyId = (function b(a) {
  // eslint-disable-next-line no-bitwise
  return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
}());

export const buildBid = (slot, name, adUnit) => {
  const currentSite = config.sublime[config.site];
  const { adaptor } = config.sublime;
  let placement = {};

  // Check if placement is defined
  if (typeof config.sublime[config.site].formats[config.device] !== 'undefined') {
    if (typeof config.sublime[config.site].formats[config.device][name] !== 'undefined') {
      placement = config.sublime[config.site].formats[config.device][name];
    }
  }

  // Disable Sublime on lemondeEN desktop
  if (config.adUnit.includes('LM_lemonde_en') && config.device === 'desktop') {
    placement = {};
  }

  if (
    typeof currentSite !== 'undefined'
    && config.group !== 'radiofrance'
    && Object.keys(placement).length > 0
  ) {
    const { zoneId } = config.sublime[config.site].formats[config.device][name];

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        zoneId,
        notifyId: window.sublime.notifyId,
      },
    });

    loadScript(`https://sac.ayads.co/sublime/${zoneId}/prebid`);
    log('load sublime script');
  }
};
