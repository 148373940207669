/* global window, document, googletag */
import { config } from '../wrapperConfig';
import {
  getNextIdBySlotFormat,
  log,
  loadScript,
  sortSlots,
  isElementComingInViewport,
  removeUnloadedClassName,
  readCookie,
  excludeAdUnit,
} from '../lib/utils';
import { setTargets } from './targeting';
import { setRoadblock } from './refresh';
import {
  renderAds,
  renderAdsOnPage,
  onSlotRenderEnded,
  onSlotOnload,
  setStyleOnSlot,
} from './render';

let slots;

export const init = () => {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  loadScript('//securepubads.g.doubleclick.net/tag/js/gpt.js');
  log('initialize google ad manager');
};

export const configure = () => new Promise((resolve) => {
  googletag.cmd.push(() => {
    googletag.pubads().enableSingleRequest();
    googletag.pubads().disableInitialLoad();
    googletag.pubads().collapseEmptyDivs(true, true);
    googletag.pubads().setPrivacySettings({ nonPersonalizedAds: false });
    googletag.pubads().setPublisherProvidedId(readCookie(config.ppidCookieName));
    googletag.enableServices();

    slots = sortSlots();
    let i;
    let slot;

    for (i = 0; i < slots.length; i += 1) {
      slot = slots[i];
      const name = slot.getAttribute('data-format');
      const pageAdUnit = config.adUnit || '';
      const slotId = getNextIdBySlotFormat(name);

      let sizes = false;
      let slotAdUnit = false;

      if (
        typeof config.formats[name] !== 'undefined'
        && typeof config.formats[name][config.device] !== 'undefined'
        && config.formats[name][config.device].length > 0
      ) {
        sizes = config.formats[name][config.device];
        slotAdUnit = `${pageAdUnit}/${name}`;
      }

      if (sizes && slotAdUnit && !excludeAdUnit(name)) {
        slot.setAttribute('id', slotId);
        // Set 'out-of-page' to true in GAM
        if (name.includes('cover')) {
          log(`set ${name} ad unit to out-of-page in GAM`);
          googletag.defineOutOfPageSlot(
            slotAdUnit,
            slotId,
          )
            .setTargeting('pos', name)
            .setTargeting('refresh', 'false')
            .addService(googletag.pubads());
        } else if (name.includes('Interstitiel_web') && window.document.location.host
          === 'stg-www.huffingtonpost.fr') {
          log(`set ${name} Interstitiel_web ad unit to out-of-page in GAM`);
          googletag.defineOutOfPageSlot(
            slotAdUnit,
            slotId,
            googletag.enums.OutOfPageFormat.INTERSTITIAL,
          )
            .setTargeting('pos', name)
            .setTargeting('refresh', 'false')
            .addService(googletag.pubads());
        } else {
          googletag.defineSlot(
            slotAdUnit,
            sizes,
            slotId,
          )
            .setTargeting('pos', name)
            .setTargeting('refresh', 'false')
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads());
        }

        // This will only register the slot. Ad will be fetched only when refresh is called.
        googletag.display(slotId);
      }
    }

    setTargets();
    resolve(slots);
    config.gamSlots = googletag.pubads().getSlots();
  });
});

export const display = () => {
  log('display ads');
  const immediate = [];
  const lazy = [];

  config.gamSlots.forEach((node) => {
    const element = document.querySelector(`#${node.getSlotElementId()}`);
    const name = element.getAttribute('data-format');
    const pageAdUnit = config.adUnit || '';
    const position = element.getBoundingClientRect().top;
    const viewport = window.innerHeight;
    // Force style on slot if needed
    setStyleOnSlot(element);

    // Alternate way to lazyload for huffpost
    if (config.site === 'huffpost') {
      if (
        name === 'banniere_haute'
        || name === 'cover'
        || name === 'pave_haut'
      ) {
        immediate.push(node);
      } else {
        lazy.push(node);
      }
    }

    // Sort slots between those immediately displayed and those lazy loaded
    if (config.site !== 'huffpost') {
      if (
        config.lazyload.excludedFormats.includes(name)
        || isElementComingInViewport(node)
        || node.getOutOfPage()
      ) {
        // Manually send inread from live sport pages into lazyload
        if (
          name.includes('inread')
          && pageAdUnit.includes('sport')
          && pageAdUnit.includes('live')
        ) {
          lazy.push(node);
          log(`#${name} slot is sent to lazyload`);
        } else {
          log(`#${name} slot is at ${position}px while viewport is ${viewport}px and displayed immediately`);
          immediate.push(node);
        }
      } else {
        log(`#${name} slot is sent to lazyload`);
        lazy.push(node);
      }
    }
  });

  // Display slot when it is coming in viewport
  const handler = () => {
    lazy.forEach((node, index) => {
      if (isElementComingInViewport(node)) {
        log(`load ${node.getSlotElementId()} slot`);
        googletag.pubads().refresh([node]);
        lazy.splice(index, 1);
      }
    });

    if (lazy.length < 1) {
      document.removeEventListener('scroll', handler);
    }
  };

  googletag.cmd.push(() => {
    if (immediate.length > 0) {
      log(`immediate display for ${immediate.length} slot(s)`);
      googletag.pubads().refresh(immediate);
    }

    log(`lazyload ${lazy.length} slot(s)`);
    document.addEventListener('scroll', handler, false);
  });

  // Execute callback when ad has been rendered & if not in subscription mode
  if (
    config.subscriber) {
    log('no conditional rendering because open auction is disabled');
  } else {
    renderAds();
    renderAdsOnPage();
  }

  removeUnloadedClassName(config);
  // Execute callback when ad has been rendered
  googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded);
  googletag.pubads().addEventListener('slotRenderEnded', setRoadblock);
  googletag.pubads().addEventListener('slotOnload', onSlotOnload);
};
