/* global document */
export default {
  slots: '.dfp-slot',
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');

    banner.removeAttribute('class');
    banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; overflow: hidden;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; max-width: 1000px;';
    banner.style.cssText = 'position: absolute; left: 0;';
  }),
  refresh: {
    excludedFormats: ['cover'],
  },
  excludeAdUnits: {
    breakpoint: '1024px',
    names: [
      'pave_haut',
      'pave_milieu',
      'pave_bas',
    ],
  },
  // Specific to lemondeEN
  pubstack: '//boot.pbstck.com/v1/tag/b1b9c58b-c80f-4020-8d4d-3475b65a10bb',
  // Specific to lemondeEN
  appnexus: {
    lemondeEN: {
      publisherId: 1131427,
      banniere_haute: { placementId: 28306229 },
      banniere_milieu: { placementId: 28306232 },
      banniere_basse: { placementId: 28306190 },
      pave_haut: { placementId: 28306237 },
      pave_milieu: { placementId: 28306238 },
      pave_bas: { placementId: 28306239 },
      inread: { placementId: 28306240 },
      // Missing lemondeEN ID
      inread_top: { placementId: 28306240 },
    },
    // Same as lemonde
    refresh: {
      publisherId: 1131427,
      banniere_haute: { placementId: 24952866 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 24952865 },
      pave_haut: { placementId: 24952864 },
      pave_milieu: { placementId: 24952868 },
      pave_bas: { placementId: 24952869 },
      inread: { placementId: 24952870 },
      inread_top: { placementId: 26485415 },
    },
  },
  // Specific to lemondeEN
  indexExchange: {
    lemondeEN: {
      formats: {
        desktop: {
          banniere_haute: '924157',
          pave_haut: '924160',
          inread: '924163',
          // Missing lemondeEN ID
          inread_top: '924163',
          banniere_milieu: '924158',
          banniere_basse: '924159',
          pave_milieu: '924161',
          pave_bas: '924162',
        },
        tablet: {
          banniere_haute: '924157',
          pave_haut: '924160',
          inread: '924163',
          // Missing lemondeEN ID
          inread_top: '924163',
          banniere_milieu: '924158',
          banniere_basse: '924159',
          pave_milieu: '924161',
          pave_bas: '924162',
        },
        mobile: {
          inread: '924163',
          // Missing lemondeEN ID
          inread_top: '924163',
        },
      },
    },
  },
  // Same as lemonde
  invibes: {
    placementId: 'invibes_lemondeen',
    placementIdInreadTop: 'invibes_lemonde_top',
  },
  // Same as lemonde
  justpremium: {
    lemondeEN: {
      banniere_haute: { zone: 151237 },
    },
  },
  // Specific to lemondeEN
  mediasquare: {
    lemondeEN: {
      formats: {
        banniere_haute: { code: 'en.lemonde.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'en.lemonde.fr_rg_atf_pave_desktop' },
        // Missing lemondeEN ID
        inread_top: { code: 'en.lemonde.fr_inread_mtf_pave_desktop' },
        inread: { code: 'en.lemonde.fr_inread_mtf_pave_desktop' },
        banniere_milieu: { code: 'en.lemonde.fr_rg_mtf_mban_desktop' },
        banniere_basse: { code: 'en.lemonde.fr_rg_btf_mban_desktop' },
        pave_milieu: { code: 'en.lemonde.fr_rg_mtf_pave_desktop' },
        pave_bas: { code: 'en.lemonde.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to lemondeEN
  outbrain: {
    lemondeEN: {
      formats: {
        banniere_haute: 'ATF-3/LMIE_banniere_haute',
        pave_haut: 'ATF-3/LMIE_pave_haut',
        inread_top: 'ATF-2/LMIE_inread_top',
        inread: 'ATF-2/LMIE_inread',
        pave_milieu: 'ATF-3/LMIE_pave_milieu',
        pave_bas: 'ATF-3/LMIE_pave_bas',
        banniere_basse: 'ATF-3/LMIE_banniere_basse',
      },
    },
  },
  // Specific to lemondeEN
  smart: {
    lemondeEN: {
      siteId: 563098,
      pageId: 1709894,
    },
  },
  // Specific to lemondeEN
  sublime: {
    lemondeEN: {
      formats: {
        desktop: {
          // Missing lemondeEN ID
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 37433 },
        },
      },
    },
  },
  // Specific to lemondeEN
  teads: {
    lemondeEN: {
      formats: {
        banniere_haute: { placementId: 178752, pageId: 164015 },
        pave_haut: { placementId: 178753, pageId: 164016 },
        inread_top: { placementId: 174879, pageId: 160287 },
        inread: { placementId: 174879, pageId: 160287 },
        pave_milieu: { placementId: 178754, pageId: 164017 },
        pave_bas: { placementId: 178755, pageId: 164018 },
        banniere_basse: { placementId: 178756, pageId: 164019 },
      },
    },
  },
  // Specific to lemondeEN
  triplelift: {
    lemondeEN: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'inread',
        ],
      },
      inventoryCode: 'lmie_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemondeEN-io-wrapper/v2/mapping/smart-consentless-lemondeEN.json',
  smartAdCallConfig: {},
};
