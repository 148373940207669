/* eslint-disable import/no-cycle */
/* global googletag, performance, window, document, pbjs */
import { config } from '../wrapperConfig';
import {
  getParameters,
  log,
} from '../lib/utils';

export const setTargets = () => {
  config.targets.testScenario = config.prebid.testScenario;
  config.targets.test = getParameters('dfptest');

  Object.keys(config.targets).forEach((index) => {
    if (!Array.isArray(config.targets[index])) {
      googletag.pubads().setTargeting(index, [`${config.targets[index]}`]);
    } else {
      googletag.pubads().setTargeting(index, config.targets[index].map((value) => `${value}`));
    }
  });
};

export const setTargeting = () => {
  let Targeting = {
    nt: (() => {
      if (
        typeof performance !== 'undefined'
        && typeof performance.navigation !== 'undefined'
        && typeof performance.navigation.type !== 'undefined'
      ) {
        return performance.navigation.type;
      }

      if (document.referrer === window.location.href) {
        return 1;
      }

      return 0;
    })(),
  };

  if (typeof window.ADS_CONFIG !== 'undefined' && typeof window.ADS_CONFIG.targets !== 'undefined') {
    log('set targeting');
    Targeting = {
      ...Targeting,
      ...window.ADS_CONFIG.targets,
    };
  }

  return Targeting;
};

const getHighestBids = () => {
  const allBids = pbjs.getBidResponses();
  const bidsOrdered = [];

  // return highest cpm from resulting list, or return null
  const selectHighestBid = (bids) => {
    bids = bids.filter((bid) => bid.cpm > 0);
    bids.sort((a, b) => b.cpm - a.cpm);
    return bids.length > 0 ? bids[0] : null;
  };

  Object.values(allBids).forEach((bid) => {
    bidsOrdered.push(selectHighestBid(bid.bids));
  });

  return bidsOrdered;
};

export const setGamTargeting = () => {
  const bids = getHighestBids();
  let tagKeywords = {};
  let keyValueToPrimary = '';

  bids.forEach((bid) => {
    keyValueToPrimary = '';

    if (!bid) {
      return;
    }

    if (bid.getStatusCode() === 1) {
      tagKeywords = {};
      tagKeywords.hb_partner_key = bid.bidder;

      Object.keys(bid.adserverTargeting).forEach((key) => {
        // Add prebid targeting tag-level configuration to render the creative later
        tagKeywords[keyValueToPrimary + key] = bid.adserverTargeting[key] || '';
      });

      // Loop over the GAM ad slots to find the one which is equal to this target id
      config.gamSlots.forEach((slot) => {
        if (slot.getAdUnitPath() === bid.adUnitCode || slot.getSlotElementId() === bid.adUnitCode) {
          // Set slot-level targeting
          Object.keys(tagKeywords).forEach((key) => {
            slot.setTargeting(key, tagKeywords[key].toString());
          });
        }
      });
    } else {
      // Loop over the GAM ad slots to find the one which is equal to this target id
      config.gamSlots.forEach((slot) => {
        if (slot.getAdUnitPath() === bid.adUnitCode || slot.getSlotElementId() === bid.adUnitCode) {
          // clear slot-level targeting
          pbjs.bidderSettings.standard.adserverTargeting.forEach((object) => {
            slot.clearTargeting(keyValueToPrimary + object.key);
          });
        }
      });
    }
  });
};
