import { config } from '../../wrapperConfig';

export const getPublisherSubId = (adUnitPath) => adUnitPath.split('/').splice(2).join('_');

export const buildBid = (slot, name, adUnit) => {
  const { adaptor, networkId } = config.criteo;
  const placement = config.criteo.formats.indexOf(name);

  if (typeof adaptor !== 'undefined' && placement > -1) {
    // Required Prebid parameters to build Criteo ad units
    const publisherSubId = getPublisherSubId(slot.getAdUnitPath());

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        networkId,
        publisherSubId,
      },
    });
  }
};
