/* global document */
export default {
  slots: '.dfp-slot',
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');

    banner.removeAttribute('class');
    banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; overflow: hidden;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; max-width: 1000px;';
    banner.style.cssText = 'position: absolute; left: 0;';
  }),
  refresh: {
    excludedFormats: ['cover'],
  },
  excludeAdUnits: {
    breakpoint: '1024px',
    names: [
      'banniere_haute',
      'banniere_basse',
      'banniere_milieu',
      'pave_haut',
      'pave_milieu',
      'pave_bas',
    ],
  },
  pubstack: '//boot.pbstck.com/v1/tag/b9725a8b-9a93-4740-aaba-5e304966592e',
  appnexus: {
    lemonde: {
      publisherId: 1131427,
      banniere_haute: { placementId: 21250115 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 21250107 },
      banniere_sticky_live_sport: { placementId: 28338973 },
      pave_haut: { placementId: 21250130 },
      pave_milieu: { placementId: 21250131 },
      pave_bas: { placementId: 21250132 },
      inread: { placementId: 21250134 },
      inread_top: { placementId: 26485414 },
    },
    refresh: {
      publisherId: 1131427,
      banniere_haute: { placementId: 24952866 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 24952865 },
      // Not a refresh ID
      banniere_sticky_live_sport: { placementId: 28338973 },
      pave_haut: { placementId: 24952864 },
      pave_milieu: { placementId: 24952868 },
      pave_bas: { placementId: 24952869 },
      inread: { placementId: 24952870 },
      inread_top: { placementId: 26485415 },
    },
  },
  indexExchange: {
    lemonde: {
      formats: {
        desktop: {
          banniere_haute: '766706',
          pave_haut: '766700',
          inread: '766705',
          inread_top: '766705',
          banniere_milieu: '766704',
          banniere_basse: '766708',
          banniere_sticky_live_sport: '924169',
          pave_milieu: '766703',
          pave_bas: '766707',
        },
        tablet: {
          banniere_haute: '766706',
          pave_haut: '766700',
          inread: '766705',
          inread_top: '766705',
          banniere_milieu: '766704',
          banniere_basse: '766708',
          banniere_sticky_live_sport: '924169',
          pave_milieu: '766703',
          pave_bas: '766707',
        },
        mobile: {
          inread: '766705',
          inread_top: '766705',
          banniere_sticky_live_sport: '924169',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_lemonde',
    placementIdInreadTop: 'invibes_lemonde_top',
  },
  justpremium: {
    lemonde: {
      banniere_haute: { zone: 151237 },
    },
  },
  mediasquare: {
    lemonde: {
      formats: {
        banniere_haute: { code: 'lemonde.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'lemonde.fr_rg_atf_pave_desktop' },
        inread_top: { code: 'lemonde.fr_inread-top_atf_pave_desktop' },
        inread: { code: 'lemonde.fr_inread_mtf_pave_desktop' },
        banniere_milieu: { code: 'lemonde.fr_rg_mtf_mban_desktop' },
        banniere_basse: { code: 'lemonde.fr_rg_btf_mban_desktop' },
        banniere_sticky_live_sport: { code: 'lemonde.fr_sticky-live_atf_mban_desktop' },
        pave_milieu: { code: 'lemonde.fr_rg_mtf_pave_desktop' },
        pave_bas: { code: 'lemonde.fr_rg_btf_pave_desktop' },
      },
    },
  },
  outbrain: {
    lemonde: {
      formats: {
        banniere_haute: 'ATF-3/LM_lemonde_banniere_haute',
        pave_haut: 'ATF-3/LM_lemonde_pave_haut',
        inread_top: 'ATF-2/LM_lemonde_inread_top',
        inread: 'ATF-2/LM_lemonde_inread',
        pave_milieu: 'ATF-3/LM_lemonde_pave_milieu',
        banniere_milieu: 'ATF-3/LM_lemonde_banniere_milieu',
        pave_bas: 'ATF-3/LM_lemonde_pave_bas',
        banniere_basse: 'ATF-3/LM_lemonde_banniere_basse',
        inread_live_sport: 'ATF-3/LM_inread_live_sport',
        banniere_sticky_live_sport: 'ATF-3/LM_banniere_sticky_live_sport',
      },
    },
  },
  smart: {
    lemonde: {
      siteId: 268240,
      pageId: 1187330,
    },
  },
  sublime: {
    lemonde: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
          cover_live_sport: { zoneId: 37437 },
        },
      },
    },
  },
  teads: {
    lemonde: {
      formats: {
        banniere_haute: { placementId: 178732, pageId: 163995 },
        pave_haut: { placementId: 178842, pageId: 164107 },
        inread_top: { placementId: 125950, pageId: 116058 },
        inread: { placementId: 125950, pageId: 116058 },
        pave_milieu: { placementId: 178734, pageId: 163997 },
        banniere_milieu: { placementId: 178735, pageId: 163998 },
        pave_bas: { placementId: 178736, pageId: 163999 },
        banniere_basse: { placementId: 178737, pageId: 164000 },
        inread_live_sport: { placementId: 174567, pageId: 159984 },
        banniere_sticky_live_sport: { placementId: 178738, pageId: 164001 },
      },
    },
  },
  triplelift: {
    lemonde: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'LeMonde_HB_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
