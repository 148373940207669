/* global window, googletag, pbjs */
import { log, calculateCpmBucket } from '../lib/utils';
import { setGamTargeting } from './targeting';
import { config } from '../wrapperConfig';

// Initialize Prebid modules list
let prebidModules = [];

const buildAdUnits = (type) => {
  log(`build prebid ${type} ad units`);
  const slots = config.gamSlots;
  const adUnits = [];

  slots.forEach((slot) => {
    const name = slot.getAdUnitPath().split('/').slice(-1)[0];

    // Cycle through prebid modules list and build a bid for each module
    const buildModulesBid = () => {
      if (config.adUnit.includes('vodkaster') && name.includes('banniere_haute')) {
        log('prevent building prebid ad units for banniere_haute on vodkaster')
      } else {
        prebidModules.forEach((module) => {
          module.buildBid(slot, name, adUnit, type);
        });
      }

      if (adUnit.bids.length > 0) {
        adUnits.push(adUnit);
      }
    }

    // Convert 'out-of-page' and 'fluid' sizes to 1x1 numeric format (only for prebid)
    let convertedSizes = [...config.formats[name][config.device]];
    const fluid = convertedSizes.indexOf('fluid');
    const outOfPage = convertedSizes.indexOf('out-of-page');

    if (fluid !== -1) {
      convertedSizes[fluid] = [1, 1];
    }

    if (outOfPage !== -1) {
      convertedSizes[outOfPage] = [1, 1];
    }

    // Build ad unit default template
    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      bids: [],
    };

    // Build ad unit of mediatype banner
    if (type === 'banner') {
      adUnit.mediaTypes = {
        banner: {
          sizes: convertedSizes,
        },
      };

      buildModulesBid();
    }

    // Build ad unit of mediatype video
    if (type === 'video'
      && name.includes('inread')
    ) {
      adUnit.renderer = {
        url: 'http://cdn.adnxs.com/renderer/video/ANOutstreamVideo.js',
      };
      adUnit.mediaTypes = {
        video: {
          context: 'outstream',
          playerSize: [640, 480],
          mimes: [
            'video/mp4',
            'video/webm',
            'application/javascript'
          ],
          maxduration: 300,
          api: [1, 2],
          protocols: [1, 2, 3, 4, 5, 6, 7, 8],
          skip: 1,
          playbackmethod: 2,
          placement: 3,
          linearity: 2,
        }
      };

      buildModulesBid();
    }
  });

  return adUnits;
};

export const doBid = (callback) => {
  pbjs.que.push(() => {
    log('request bids');
    const start = window.performance.now();
    pbjs.requestBids({
      bidsBackHandler: (bidResponses) => {
        let bidAvailables = 0;
        log('response bids');
        const end = window.performance.now();
        // Record the time spent by the bidding process
        config.loadingTime.biddingProcess = Math.round(end - start);

        if (Object.keys(bidResponses).length !== 0) {
          log('there are bids');
          Object.keys(bidResponses).forEach((name) => {
            if (bidResponses[name].bids && bidResponses[name].bids.length > 0) {
              bidResponses[name].bids.forEach((bid) => {
                if (bid.getStatusCode() === 1) {
                  bidAvailables += 1;
                  log(`${name} -> ${bid.bidderCode} -> ${bid.statusMessage}`);
                }
              });
            }
          });
        }

        if (bidAvailables === 0) {
          log('no bids available');
        }

        setGamTargeting();

        if (callback) {
          callback();
        }
      },
      timeout: config.prebid.timeout,
    });
  });
};

export const sendBid = () => new Promise((resolve) => {
  doBid(resolve);
});

export const configure = () => {
  const setBidderSettings = () => {
    const customBidderSettings = {};

    // Set custom key/values pairs for each SSP module
    config.prebid.modules.forEach((module) => {
      customBidderSettings[module] = {
        adserverTargeting: [{
          key: `hb_bidder_${module}`.substring(0, 20),
          val: (bidResponse) => bidResponse.bidderCode,
        }, {
          key: `hb_pb_${module}`,
          val: calculateCpmBucket,
        }],
      };
    });

    return customBidderSettings;
  }

  setBidderSettings();

  const customGranularity = {
    "buckets": config.prebid.granularities.default,
  }
  config.pbjsConfig.priceGranularity = customGranularity;

  pbjs.que.push(() => {
    pbjs.bidderSettings = setBidderSettings();
    log('configure prebid');
  });
  pbjs.setConfig(config.pbjsConfig);
  pbjs.addAdUnits(buildAdUnits('banner'));
  pbjs.addAdUnits(buildAdUnits('video'));
  log(pbjs.adUnits)
};

export const init = () => {
  window.pbjs = window.pbjs || {};
  pbjs.que = pbjs.que || [];
  pbjs.renderSkin = config.renderSkin;

  import(
    /* webpackChunkName: "prebid" */
    /* webpackMode: "eager" */
    `../lib/vendor/prebid${config.prebid.version}`
  );
  log(`load prebid ${config.prebid.version}`);

  prebidModules = [];
  const modulesList = config.prebid.modules;

  modulesList.forEach((module) => {
    import(
      /* webpackChunkName: "prebid-modules" */
      /* webpackMode: "eager" */
      `../lib/prebid/${module}`
    )
      .then((module) => {
        prebidModules.push(module);
      });
  });
};