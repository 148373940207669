/* global window */
import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit, type) => {
  if (type === 'banner') {
    if (
      typeof config.outbrain !== 'undefined'
      && typeof config.outbrain[config.site] !== 'undefined'
      && typeof config.outbrain[config.site].formats[name] !== 'undefined'
      && name !== 'banniere_haute'
    ) {
      const { adaptor, id } = config.outbrain;
      const tagid = config.outbrain[config.site].formats[name];
      const domain = window.location.hostname.substring(4);

      adUnit.bids.push({
        bidder: adaptor,
        params: {
          publisher: {
            id,
            name: config.site,
            domain,
          },
          tagid,
        },
      });
    }
  }
};
