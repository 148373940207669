import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  let code = '';

  if (typeof config.mediasquare[config.site] !== 'undefined') {
    if (typeof config.mediasquare[config.site].formats[name] !== 'undefined') {
      code = config.mediasquare[config.site].formats[name].code;
    }
  }

  if (
    typeof config.mediasquare !== 'undefined'
    && code.length > 0
  ) {
    const { adaptor, owner } = config.mediasquare;

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        owner,
        code,
      },
    });
  }
};
