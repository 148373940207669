/* global googletag, window */
import { loadScript, log } from '../lib/utils';
import { config } from '../wrapperConfig';

const getSizes = (slot) => {
  const sizes = slot.getSizes().map((size) => {
    if (typeof size === 'string') {
      return size;
    }
    return [size.getWidth(), size.getHeight()];
  }).filter((size) => {
    if (size[0] === 1800) {
      return false;
    }
    return Array.isArray(size) && (![1, 2].includes(size[0]) || ![1, 2].includes(size[1]));
  });

  return sizes;
};

/* Generate the ad units based on the page's tags */
const generateAmazonAdUnits = () => {
  const slots = config.gamSlots.map((slot) => ({
    slotID: slot.getSlotElementId(),
    slotName: slot.getAdUnitPath(),
    sizes: getSizes(slot),
  })).filter(
    (slot) => slot.sizes.length > 0
      && !slot.slotID.includes(config.amazonExcludedFormats),
  );

  return slots;
};

export const init = () => {
  if (typeof window.apstag !== 'undefined') return;

  /* eslint-disable no-underscore-dangle */
  window.apstag = {
    init: (...args) => {
      window.apstag._Q.push(['i', args]);
    },
    fetchBids: (...args) => {
      window.apstag._Q.push(['f', args]);
    },
    setDisplayBids: () => { },
    _Q: [],
  };

  loadScript(config.amazonScript);
  window.apstag.init(config.amazonConfig);
  log('initialize amazon TAM');
};

export const doBid = (callback) => {
  const apstagSlots = generateAmazonAdUnits();

  if (apstagSlots.length > 0) {
    window.apstag.fetchBids({
      slots: apstagSlots,
      timeout: config.prebid.timeout, // take the same timeout value as prebid
    }, () => {
      log('amazon bids returned');
      googletag.cmd.push(() => {
        window.apstag.setDisplayBids();
        if (callback) {
          callback();
        }
      });
    });
    log('amazon bid request fired');
  } else {
    log(`There are no '${config.slots}' elements provided by the current page, therefore the wrapper cannot be initialized`);
  }
};

export function doVideoBid() {
  log('start amazon video bid');
  window.apstag.fetchBids(
    {
      slots: [
        {
          slotID: 'videoSlot',
          mediaType: 'video',
        },
      ],
    },
    (bids) => {
      if (bids.length > 0) {
        log(`amazon video bid params: ${bids[0].helpers.qsParams()}`);

        // If we have received any bids back
        const event = new window.CustomEvent('amazonAdsParamsReady', {
          bubbles: true,
          detail: {
            params: () => `${bids[0].helpers.qsParams()}`,
          },
        });

        // Dispatch the event to the player script
        window.dispatchEvent(event);
        config.requestAmzVideoDone = true;
      }
    },
  );
}

export const sendBid = (slotName) => new Promise((resolve) => {
  doBid(resolve, slotName);
});
