/* global window, pbjs, googletag */
import { config } from '../wrapperConfig';
import { log } from '../lib/utils';

// Set a custom refresh interval through the query string parameter 'refresh'
const customRefreshInterval = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const customInterval = urlParams.get('refresh');

  if (typeof customInterval !== 'undefined') {
    const newInterval = parseInt(customInterval, 10);

    if (newInterval >= 9) {
      log(`set custom refresh interval to ${newInterval} seconds`);
      config.refresh.refreshIntervalDesktop = newInterval;
      config.refresh.refreshIntervalMobile = newInterval;
    }
  }

  return false;
};

// Set new placements IDs when refresh module is initialized
const setNewPlacements = () => {
  // Check for AppNexus refresh placements IDs config
  if (typeof config.appnexus.refresh !== 'undefined') {
    log('configure new refresh placements for appnexus');

    // Updating AppNexus placements IDs
    config.appnexus[config.site] = config.appnexus.refresh;

    // Looping through existing Prebid ad units
    for (let adUnit = 0; adUnit < pbjs.adUnits.length; adUnit += 1) {
      const placement = pbjs.adUnits[adUnit].code;

      // Updating existing AppNexus ad units with the refresh IDs
      for (let bid = 0; bid < pbjs.adUnits[adUnit].bids.length; bid += 1) {
        if (pbjs.adUnits[adUnit].bids[bid].bidder.includes('appnexus')) {
          if (placement.includes('-')) {
            const placementName = placement.split('-')[0];
            // eslint-disable-next-line max-len
            pbjs.adUnits[adUnit].bids[bid].params.placementId = config.appnexus[config.site][placementName].placementId;
          } else {
            // eslint-disable-next-line max-len
            pbjs.adUnits[adUnit].bids[bid].params.placementId = config.appnexus[config.site][placement].placementId;
          }
        }
      }
    }
  } else {
    log('no new refresh placements for appnexus');
  }
};

// Check if given element is in the viewport or not
const isElementInViewport = (slotElement) => {
  const position = slotElement.getBoundingClientRect();
  const innerHeight = window.innerHeight - 150;

  return (
    position.top <= innerHeight
    && position.right <= window.innerWidth
    && position.bottom >= 0
  );
};

// Update dynamically refresh excluded formats list by retrieving identical line items IDs
export const setRoadblock = (event) => {
  const {
    slot,
    lineItemId,
    advertiserId,
    campaignId,
  } = event;
  const identicalIDs = [];

  if (lineItemId !== null) {
    config.lineItems.push({
      slotName: slot.getSlotElementId(),
      lineItemId,
      advertiserId,
      campaignId,
    });
  }

  // Push line items with identical IDs in a separate array
  config.lineItems.forEach((el, i) => {
    config.lineItems.forEach((element, index) => {
      let isRoadblock = true;

      if (
        config.refresh.excludedRoadblocks.includes(element.advertiserId)
        || config.refresh.excludedRoadblocks.includes(element.campaignId)) {
        log(`${element.slotName} is in excluded roadblocks list`);
        isRoadblock = false;
      }

      if (
        i !== index
        && element.lineItemId === el.lineItemId
        && element.slotName !== el.slotName
        && isRoadblock) {
        if (!identicalIDs.includes(el)) {
          identicalIDs.push(el);
        }
      }
    });
  });

  // Positions with identicals IDs are pushed to excluded formats list
  if (identicalIDs.length > 0) {
    identicalIDs.forEach((el) => {
      if (!config.refresh.excludedFormats.includes(el.slotName)) {
        config.refresh.excludedFormats.push(el.slotName);
        log(`update refresh excluded formats: ${config.refresh.excludedFormats}`);
        log(identicalIDs);
      }
    });
  }

  // Exclude specific campaign from refresh
  if (campaignId === 3223769738) {
    config.refresh.excludedFormats.push(slot.getSlotElementId());
    log(`exclude specific position to refresh for specific campaign: ${config.refresh.excludedFormats}`);
  }
};

export const refresh = () => {
  // Set up local variables
  const slots = config.gamSlots;
  customRefreshInterval();
  const {
    refreshIntervalDesktop,
    refreshIntervalMobile,
    maxUserInactivity,
    checkSlotInterval,
  } = config.refresh;
  const refreshInterval = config.device === 'mobile' ? refreshIntervalMobile * 1000 : refreshIntervalDesktop * 1000;

  let onPage = true;
  let isActive = true;

  // Listen if user is on current page
  const isUserOnPage = () => {
    window.document.addEventListener('visibilitychange', () => {
      if (window.document.hidden) {
        onPage = false;
        log('user is not on the page anymore');
      } else {
        if (onPage === false) {
          log('user is back on the page');
        }
        onPage = true;
      }
    });
  };

  // Check if user is still active on given interval
  const isUserActive = () => {
    let secondsSinceLastActivity = 0;

    setInterval(() => {
      secondsSinceLastActivity += 1;
      if (secondsSinceLastActivity > maxUserInactivity) {
        isActive = false;
        log(`user is inactive since ${secondsSinceLastActivity} seconds`);
      }
    }, 1000);

    const activity = () => {
      isActive = true;
      secondsSinceLastActivity = 0;
    };

    const activityEvents = [
      'mousedown', 'mousemove', 'keydown',
      'scroll', 'touchstart',
    ];

    activityEvents.forEach((eventName) => {
      window.document.addEventListener(eventName, activity, true);
    });
  };

  // Remove existing line item ID position when slot is refreshed
  const updateLineItemsList = (slotName) => {
    function removeObjectWithSlotName(array) {
      const objWithSlotName = array.findIndex((obj) => obj.slotName === slotName);

      if (objWithSlotName > -1) {
        array.splice(objWithSlotName, 1);
      }

      return array;
    }

    removeObjectWithSlotName(config.lineItems);
  };

  // Trigger refresh on given slot
  const refreshSlot = (slot, slotName) => {
    if (config.subscriber) {
      slot.setTargeting('refresh', 'true');
      log(`refresh ${slotName}`);
      googletag.pubads().refresh([slot]);
    } else {
      pbjs.que.push(() => {
        pbjs.requestBids({
          adUnitCodes: [slotName],
          bidsBackHandler: () => {
            pbjs.setTargetingForGPTAsync([slotName]);
            slot.setTargeting('refresh', 'true');
            updateLineItemsList(slotName);
            log(`refresh ${slotName}`);
            googletag.pubads().refresh([slot]);
          },
        });
      });
    }
  };

  // Check if current time is equal or above refresh interval treshold since last timestamp
  const isItTimeToRefresh = (timestamp) => (Date.now() - timestamp >= refreshInterval);

  // Block refresh on banniere_haute if cover slot or Sublime is loaded
  const isSublimeOrCoverLoaded = (slotName) => {
    if (config.subscriber) {
      return false;
    }

    if (slotName === 'banniere_haute' || slotName === 'cover') {
      const coverSlot = window.document.querySelector('#cover div');
      const winningBids = pbjs.getAllWinningBids();

      if (coverSlot !== null) {
        if (coverSlot.hasChildNodes()) {
          return true;
        }
      }

      for (let index = 0; index < winningBids.length; index += 1) {
        if (winningBids[index].adUnitCode === 'banniere_haute' || winningBids[index].adUnitCode === 'cover') {
          if (winningBids[index].bidderCode === 'sublime') {
            return true;
          }
        }
      }

      return false;
    }

    return false;
  };

  // Check if slot is allowed to refresh
  const isSlotAllowedToRefresh = (slotName) => {
    if (config.refresh.excludedFormats.includes(slotName)) {
      return false;
    }

    return true;
  };

  // Check if a slot is refreshable on given interval
  const watchSlots = () => {
    for (let index = 0; index < slots.length; index += 1) {
      const slot = slots[index];
      const slotName = slot.getSlotElementId();
      const slotElement = window.document.getElementById(slotName);

      // Check first if slot has been loaded a first time into the DOM before triggering timer
      // eslint-disable-next-line no-loop-func
      const slotHasBeenLoaded = () => {
        if (isElementInViewport(slotElement)) {
          window.document.removeEventListener('scroll', slotHasBeenLoaded);
          let timestamp = Date.now();

          const isRefreshable = () => {
            if (
              isElementInViewport(slotElement)
              && isSlotAllowedToRefresh(slotName)
              && !isSublimeOrCoverLoaded(slotName)
              && isItTimeToRefresh(timestamp)
              && isActive
              && onPage) {
              refreshSlot(slot, slotName);
              timestamp = Date.now();
            }
          };

          setInterval(isRefreshable, checkSlotInterval * 1000);
        }
      };

      window.document.addEventListener('scroll', slotHasBeenLoaded, false);
    }
  };

  if (!config.subscriber) {
    setNewPlacements();
  }

  isUserOnPage();
  isUserActive();
  watchSlots();
};
