import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit, type) => {
  // Currently Teads renders outstream in banner mediatype
  if (type === 'banner') {
    const currentSite = config.teads[config.site];
    const { adaptor } = config.teads;
    let placement = {};

    // Check if placement is defined
    if (typeof config.teads[config.site].formats !== 'undefined') {
      if (typeof config.teads[config.site].formats[name] !== 'undefined') {
        placement = config.teads[config.site].formats[name];
      }
    }

    if (typeof currentSite !== 'undefined' && Object.keys(placement).length > 0) {
      const { pageId, placementId } = config.teads[config.site].formats[name];

      adUnit.bids.push({
        bidder: adaptor,
        params: {
          pageId,
          placementId,
        },
      });
    }
  }
};
