export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/08307540-a9a2-4311-afac-7b1c9f94c3ed',
  refresh: {
    excludedFormats: [],
  },
  // Specific to France Bleu
  appnexus: {
    francebleu: {
      publisherId: 2089064,
      banniere_haute: { placementId: 24955325 },
      banniere_basse: { placementId: 24955330 },
      pave_haut: { placementId: 24955336 },
      pave_milieu: { placementId: 24955340 },
      inread: { placementId: 24955342 },
      inread_top: { placementId: 24955342 },
    },
  },
  // Specific to France Bleu
  indexExchange: {
    francebleu: {
      formats: {
        desktop: {
          banniere_haute: '811475',
          pave_haut: '811476',
          inread: '811478',
          inread_top: '811478',
          banniere_basse: '811474',
          pave_milieu: '811477',
        },
        tablet: {
          banniere_haute: '811475',
          pave_haut: '811476',
          inread: '811478',
          inread_top: '811478',
          banniere_basse: '811474',
          pave_milieu: '811477',
        },
        mobile: {
          banniere_haute: '811480',
          banniere_basse: '811479',
          inread: '811481',
          inread_top: '811481',
        },
      },
    },
  },
  // Specific to France Bleu
  invibes: {
    placementId: 'invibes_francebleu',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to France Bleu
  mediasquare: {
    francebleu: {
      formats: {
        banniere_haute: { code: 'francebleu.radiofrance.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'francebleu.radiofrance.fr_rg_atf_pave_desktop' },
        inread: { code: 'francebleu.radiofrance.fr_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'francebleu.radiofrance.fr_rg_btf_mban_desktop' },
        pave_bas: { code: 'francebleu.radiofrance.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to France Bleu
  smart: {
    francebleu: {
      siteId: 505463,
      pageId: 1575233,
    },
  },
  sublime: {
    francebleu: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to France Bleu
  teads: {
    francebleu: {
      formats: {
        inread_top: { placementId: 162041, pageId: 147983 },
        inread: { placementId: 162041, pageId: 147983 },
      },
    },
  },
  triplelift: {
    francebleu: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Francebleu_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
