/* global document */
export default {
  slots: '.dfp-slot',
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const teleramaGrilleBanner = document.querySelector('#habillagepub #banniere_haute');

    document.styleSheets[0].addRule('.dfp-slot.dfp-loaded::after', 'display:none !important;');
    banner.removeAttribute('class');
    if (teleramaGrilleBanner === null) {
      banner.style.cssText = 'position: absolute; z-index: 0; margin:auto; left: 50%; transform: translateX(-50%);';
    } else {
      banner.style.cssText = 'position: absolute; z-index: -1; margin: -220px auto; left: 50%; transform: translateX(-50%);';
    }
    skin.style.cssText = 'position: relative;z-index: 1;margin: 220px auto;max-width: 1000px;';
  }),
  // Specific to teleramaGrille
  pubstack: '//boot.pbstck.com/v1/tag/2b4e4602-c87d-4282-aef7-8d706c033ac0',
  // Specific to teleramaGrille
  appnexus: {
    teleramaGrille: {
      publisherId: 1131430,
      banniere_haute: { placementId: 28306293 },
      banniere_basse: { placementId: 28306294 },
      inread: { placementId: 28306299 },
      inread_top: { placementId: 28306295 },
    },
    // Same as telerama
    refresh: {
      publisherId: 1131430,
      banniere_haute: { placementId: 25029019 },
      banniere_basse: { placementId: 25029018 },
      inread: { placementId: 25029025 },
      inread_top: { placementId: 26485475 },
    },
  },
  // Same as telerama
  invibes: {
    placementId: 'invibes_telerama',
    placementIdInreadTop: 'invibes_telerama_top',
  },
  // Specific to teleramaGrille
  indexExchange: {
    teleramaGrille: {
      formats: {
        desktop: {
          banniere_haute: '924165',
          inread: '924168',
          inread_top: '924167',
          banniere_basse: '924166',
        },
        tablet: {
          banniere_haute: '924165',
          inread: '924168',
          inread_top: '924167',
          banniere_basse: '924166',
        },
        mobile: {
          inread: '924168',
          inread_top: '924167',
        },
      },
    },
  },
  // Same as telerama
  justpremium: {
    teleramaGrille: {
      banniere_haute: { zone: 151239 },
    },
  },
  // Specific to teleramaGrille
  mediasquare: {
    teleramaGrille: {
      formats: {
        banniere_haute: { code: 'television.telerama.fr_rg_atf_mban_desktop' },
        inread_top: { code: 'television.telerama.fr_inread-top_atf_pave_desktop' },
        inread: { code: 'television.telerama.fr_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'television.telerama.fr_rg_btf_mban_desktop' },
      },
    },
  },
  // Specific to teleramaGrilleGrille
  outbrain: {
    teleramaGrille: {
      formats: {
        banniere_haute: 'ATF-3/TRA_GrilleTV_banniere_haute',
        inread_top: 'ATF-2/TRA_GrilleTV_inread_top',
        inread: 'ATF-2/TRA_GrilleTV_inread',
        banniere_basse: 'ATF-3/TRA_GrilleTV_banniere_basse',
      },
    },
  },
  // Specific to teleramaGrilleGrille
  smart: {
    teleramaGrille: {
      siteId: 563102,
      pageId: 1709899,
    },
  },
  sublime: {
    teleramaGrille: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 37435 },
        },
        mobile: {
          cover: { zoneId: 37434 },
        },
      },
    },
  },
  // Specific to teleramaGrille
  teads: {
    teleramaGrille: {
      formats: {
        banniere_haute: { placementId: 178764, pageId: 164027 },
        inread_top: { placementId: 174880, pageId: 160288 },
        inread: { placementId: 174880, pageId: 160288 },
        banniere_basse: { placementId: 178765, pageId: 164028 },
      },
    },
  },
  // Specific to teleramaGrille
  triplelift: {
    teleramaGrille: {
      formats: {
        desktop: [
          'banniere_haute',
          'inread',
          'inread_top',
          'banniere_basse',
        ],
        tablet: [
          'banniere_haute',
          'inread',
          'inread_top',
          'banniere_basse',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'TRA_TV_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-teleramaGrille.json',
  smartAdCallConfig: {},
};
