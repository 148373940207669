/* eslint-disable import/no-cycle */
import {
  getParameters,
  setAdUnit,
  setModules,
  mergeConfigs,
  onRouteChange,
  customTimeout,
} from './lib/utils';
import { detectSite, detectDevice } from './lib/utils/detect';
import SiteFactory from './lib/utils/sites';
import { setTargeting } from './modules/targeting';

export const config = {
  version: '1.4.14@fix-lazyload-inreads-live-sport',
  group: '',
  site: detectSite(SiteFactory().getSites()),
  device: detectDevice(),
  adUnit: setAdUnit(),
  targets: setTargeting(),
  slots: '.adSlot',
  lineItems: [],
  unloadedClassName: 'dfp-unloaded',
  loadingClassName: 'dfp-loading',
  formats: {},
  wrapperType: '',
  ppidCookieName: 'lead',
  loadingTime: {},
  lazyload: {
    threshold: 150,
    excludedFormats: [],
  },
  refresh: {
    excludedFormats: [],
    excludedRoadblocks: [
      '6420423257',
      '4721644312',
      '4648418632',
      '5018420595',
      '4739923417',
      '61543561',
      '4796557567',
      '5120937724',
      '5000687163',
      '4642811511',
      '4836686536',
      '5079474866',
      '5239877970',
      '422100721',
    ],
    maxUserInactivity: 90,
    refreshIntervalDesktop: 25,
    refreshIntervalMobile: 20,
    checkSlotInterval: 1,
  },
  // Analytics scripts URLs
  pubstack: '//boot.pbstck.com/v1/tag/b9725a8b-9a93-4740-aaba-5e304966592e',
  grapeshot: '//le-monde-ams.gscontxt.net/main/channels.cgi?url=',
  // Overall timeout
  timeout: customTimeout(),
  // Prebid configs
  prebid: {
    defaultPrecision: 2,
    granularities: {
      default: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01,
        }, {
          precision: 2,
          min: 5,
          max: 20,
          increment: 0.05,
        }, {
          precision: 2,
          min: 20,
          max: 70,
          increment: 0.5,
        },
      ],
    },
    modules: setModules(),
    testScenario: getParameters('testScenario') || '',
    // Prebid request timeout
    timeout: customTimeout(),
    // Prebid.js library version
    version: '8.15.0',
  },
  pbjsConfig: {
    consentManagement: {
      gdpr: {
        cmpApi: 'iab',
        timeout: 8000,
        allowAuctionWithoutConsent: true,
        defaultGdprScope: true,
      },
    },
    // Prebid failsafe timeout
    bidderTimeout: customTimeout(),
    currency: {
      adServerCurrency: 'EUR',
      granularityMultiplier: 1,
      defaultRates: { USD: { EUR: 0.89 } },
    },
    useBidCache: true,
    cache: {
      url: 'https://prebid.adnxs.com/pbc/v1/cache',
    },
    // For Smile Wanted config
    userSync: {
      iframeEnable: true,
      filterSettings: {
        iframe: {
          bidders: '*',
          filter: 'include',
        },
        image: {
          bidders: '*',
          filter: 'include',
        },
      },
      syncDelay: 3000,
      syncEnabled: true,
      syncsPerBidder: 5,
    },
    outbrain: {
      bidderUrl: 'https://b1h-euc1.zemanta.com/api/bidder/prebid/bid/',
      usersyncUrl: 'https://b1h-euc1.zemanta.com/usersync/prebid',
    },
    criteo: {
      fastBidVersion: 'latest',
    },
  },
  winningBids: {},
  // Prebid SSPs modules configs
  appnexus: {
    member: 8499,
    adaptor: 'appnexus',
  },
  criteo: {
    adaptor: 'criteo',
    networkId: 8230,
    formats: [
      'banniere_haute',
      'pave_haut',
      'inread_top',
      'inread',
      'banniere_milieu',
      'banniere_basse',
      'banniere_sticky_live_sport',
      'pave_milieu',
      'pave_bas',
    ],
  },
  indexExchange: {
    adaptor: 'ix',
    size: [640, 480],
    video: {
      mimes: [
        'video/mp4',
        'video/webm',
        'video/mpg',
        'application/javascript',
      ],
      w: 640,
      h: 480,
      startdelay: 0,
      minduration: 5,
      maxduration: 180,
      protocols: [2, 3, 5, 6],
      api: [1, 2],
    },
  },
  invibes: {
    adaptor: 'invibes',
    formats: [
      'inread_top',
      'inread',
    ],
    endpoint: 'https://prebid.videostep.com/Bid/VideoAdContent',
  },
  justpremium: {
    adaptor: 'justpremium',
  },
  mediasquare: {
    adaptor: 'mediasquare',
    owner: 'mpublicite',
  },
  outbrain: {
    adaptor: 'outbrain',
    id: '00532cf4102db2ad97ecef1d0b3cc806fb',
  },
  smart: {
    adaptor: 'smartadserver',
    formats: {
      banniere_haute: { formatId: 70664 },
      banniere_milieu: { formatId: 90457 },
      banniere_basse: { formatId: 70665 },
      banniere_sticky_live_sport: { formatId: 118253 },
      pave_haut: { formatId: 70666 },
      pave_milieu: { formatId: 70667 },
      pave_bas: { formatId: 70668 },
      inread: { formatId: 70670 },
      inread_top: { formatId: 114273 },
    },
  },
  sublime: {
    adaptor: 'sublime',
  },
  teads: {
    adaptor: 'teads',
  },
  triplelift: {
    adaptor: 'triplelift',
    overridedSizes: {},
  },
  // Amazon header bidding (TAM) config
  amazonScript: '//c.amazon-adsystem.com/aax2/apstag.js',
  amazonExcludedFormats: [
    'inread_live_sport',
  ],
  amazonConfig: {
    pubID: '3789',
    adServer: 'googletag',
    videoAdServer: 'DFP',
    deals: true,
  },
  requestAmzVideoDone: false,
  // Smart Consentless config
  smartConsentlessScript: '//ced.sascdn.com/tag/3937/smart.js',
  smartConsentlessData: '',
  smartAdCallConfig: {},
  smartAdNetworkid: 3937,
  smartAdDomain: '//diff.smartadserver.com',
  // Staging scripts URLs
  stagingScript: 'https://wrapper.lemde.fr/v2/glmaw-staging.js',
  stagingConsentlessScript: 'https://wrapper.lemde.fr/v2/consentless-staging.js',
};

// Merge inherited config with site-specific config
mergeConfigs();
// For test-SPA only, update & merge configs on route change
onRouteChange();
