/* global window, document, googletag */
import { config } from '../wrapperConfig';
import { loadScript, log } from '../lib/utils';

export const load = () => new Promise((resolve) => {
  const url = window.location !== window.top.location ? document.referrer : window.location;

  window.gs_channels = 'DEFAULT';

  log('load grapeshot script');
  const grapeshotTest = window.performance.now();
  loadScript(`${config.grapeshot}${encodeURIComponent(url)}`).then(() => {
    const grapeshotEnd = window.performance.now();
    config.loadingTime.grapeshot = Math.round(grapeshotEnd - grapeshotTest);
    googletag.cmd.push(() => {
      log(`grapeshot targeting set ${window.gs_channels}`);
      googletag.pubads().setTargeting('gs_cat', window.gs_channels);
      resolve();
    });
  });
});
