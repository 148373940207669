/* eslint-disable import/no-cycle */
/* global window */
import { log } from '..';
import { config } from '../../../wrapperConfig';
import { phone, tablet } from './device';

export const detectDevice = () => {
  if (phone !== false) {
    return 'mobile';
  }

  if (tablet !== false) {
    return 'tablet';
  }

  return 'desktop';
};

// Target breakpoints for Radio France websites to assign corresponding device
export const detectBreakPoint = () => {
  if (config.group === 'radiofrance') {
    if (window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches) {
      log(`breakpoint for ${config.site}: tablet`);
      config.device = 'tablet';
    } else if (window.matchMedia('(min-width: 1280px)').matches) {
      log(`breakpoint for ${config.site}: desktop`);
      config.device = 'desktop';
    } else {
      log(`breakpoint for ${config.site}: mobile`);
      config.device = 'mobile';
    }
  }
};

// Detect current website, if none detected fallback on 'lemonde' by default
export const detectSite = (siteList, url = window.document.location.hostname) => siteList
  .reduce((accumulator, given) => {
    const regex = new RegExp(given.regex);

    if (regex.test(url)) {
      accumulator = given.name;
    }

    // For lemondeEN
    if (window.document.location.href.includes('www.lemonde.fr/en/')) {
      accumulator = 'lemondeEN';
      return accumulator;
    }

    // For Huffpost staging
    if (url === 'www.stg-huffpost.loc-lemonde.fr') {
      accumulator = 'huffpost';
      return accumulator;
    }

    return accumulator;
  }, '') || 'lemonde';

export const detectGroup = () => {
  // Detect if current domain belongs to Radio France
  if (window.location.href.includes('radiofrance') || window.location.href.includes('francebleu')) {
    config.group = 'radiofrance';
    config.site = 'radiofrance';

    const radioFranceWebsites = [
      'francemusique',
      'fip',
      'franceculture',
      'franceinter',
      'mouv',
      'francebleu',
    ];

    // SET corresponding config to current radiofrance website
    for (let i = 0; i < radioFranceWebsites.length; i += 1) {
      if (window.location.href.includes(radioFranceWebsites[i])) {
        config.site = radioFranceWebsites[i];
        break;
      }
    }

    // Add staging domains to set specific config & group
    const { hostname } = window.document.location;
    const francebleuStagingDomains = [
      'francebleu-frontstaging.uzine.radiofrance.fr',
      'francebleu-frontpreprod.uzine.radiofrance.fr',
      'bleu-cdnpreprod.priv.radiofrance.dev',
      'bleu-cdnstaging.priv.radiofrance.dev',
      'bweb-2159-ad-bleu-cdnstaging.priv.radiofrance.dev',
    ];

    if (francebleuStagingDomains.includes(hostname)) {
      config.site = 'francebleu';
      config.group = 'radiofrance';
    }

    // Set radiofrance group to all radiofrance domains
    const radiofranceDomains = [
      'webrf-cdnstaging.priv.radiofrance.dev',
      'www.radiofrance.fr',
      'www.francebleu.fr',
      'www.franceinter.fr',
      'www.franceculture.fr',
      'www.mouv.fr',
    ];

    if (radiofranceDomains.includes(hostname)) {
      config.group = 'radiofrance';
    }

    log(`load config for ${config.site} of ${config.group}`);
  } else {
    config.group = 'groupelemonde';
    log(`load config for ${config.site} of ${config.group}`);
  }
};
