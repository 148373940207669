export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/d9ba314d-246c-48be-9d6c-56115639c58a',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  formats: {
    banniere_milieu: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugacion-es.json',
  smartAdCallConfig: {},
  appnexus: {
    conjugacion: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388084 },
      banniere_haute: { placementId: 19613649 },
      pave_haut: { placementId: 19613650 },
      pave_milieu: { placementId: 19613652 },
      inread: { placementId: 19613651 },
      inread_top: { placementId: 26485458 },
    },
  },
  indexExchange: {
    conjugacion: {
      formats: {
        desktop: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
        tablet: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
        mobile: {
          banniere_haute: '819133',
          pave_haut: '819139',
          inread: '819140',
          inread_top: '819140',
          banniere_basse: '819141',
          pave_milieu: '819142',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_conjugacion',
    placementIdInreadTop: 'invibes_conjugacion_top',
  },
  mediasquare: {
    conjugacion: {
      formats: {
        banniere_haute: { code: 'conjugacion.es_rg_atf_mban_desktop' },
        pave_haut: { code: 'conjugacion.es_rg_atf_pave_desktop' },
        inread_top: { code: 'conjugacion.es_inread-top_atf_pave_desktop' },
        inread: { code: 'conjugacion.es_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'conjugacion.es_rg_btf_mban_desktop' },
        pave_milieu: { code: 'conjugacion.es_rg_mtf_pave_desktop' },
      },
    },
  },
  outbrain: {
    conjugacion: {
      formats: {
        banniere_haute: 'ATF-3/ConjugacionES_banniere_haute',
        pave_haut: 'ATF-3/ConjugacionES_pave_haut',
        pave_milieu: 'ATF-3/ConjugacionES_pave_milieu',
        inread_top: 'ATF-2/ConjugacionES_inread_top',
        inread: 'ATF-2/ConjugacionES_inread',
        banniere_basse: 'ATF-3/ConjugacionES_banniere_basse',
      },
    },
  },
  smart: {
    conjugacion: {
      siteId: 506177,
      pageId: 1577414,
    },
  },
  sublime: {
    conjugacion: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 32116 },
        },
        mobile: {
          cover: { zoneId: 32115 },
        },
      },
    },
  },
  teads: {
    conjugacion: {
      formats: {
        banniere_haute: { placementId: 178804, pageId: 164067 },
        banniere_basse: { placementId: 178807, pageId: 164070 },
        pave_haut: { placementId: 178805, pageId: 164068 },
        pave_milieu: { placementId: 178806, pageId: 164069 },
        inread_top: { placementId: 128649, pageId: 118573 },
        inread: { placementId: 128649, pageId: 118573 },
      },
    },
  },
  triplelift: {
    conjugacion: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_conjugaciones_',
    },
  },
};
