import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  let zone = 0;

  if (typeof config.justpremium[config.site] !== 'undefined') {
    if (typeof config.justpremium[config.site][name] !== 'undefined') {
      zone = config.justpremium[config.site][name].zone;
    }
  }

  // Disable JustPremium on lemondeEN
  if (config.adUnit.includes('LM_lemonde_en') && config.device === 'desktop') {
    zone = 0;
  }

  if (
    typeof config.justpremium !== 'undefined'
    && zone > 0
  ) {
    const { adaptor } = config.justpremium;
    adUnit.bids.push({
      bidder: adaptor,
      params: {
        zone,
      },
    });
  }
};
