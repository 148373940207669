/* global window, document, pbjs, MutationObserver */
import { log } from '../lib/utils';
import { config } from '../wrapperConfig';

// Customize ads render depending on winning bidder
export const renderAds = () => {
  const currentSite = config.site;
  const banniereHaute = document.getElementById('banniere_haute');

  pbjs.onEvent('bidWon', (data) => {
    const {
      adUnitCode,
      bidderCode,
      cpm,
      width,
    } = data;

    log(`bid won -> ${adUnitCode} -> ${bidderCode} -> ${cpm}`);
    config.winningBids[adUnitCode] = bidderCode;

    switch (currentSite) {
      case 'lemonde': case 'lemondeEN':
        // Bannière haute - arches
        if (adUnitCode === 'banniere_haute' && width === 1800) {
          if (bidderCode === 'smartadserver' || bidderCode === 'ix' || bidderCode === 'appnexus') {
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
            const habillagePub = document.querySelector('#habillagepub');
            banniereHaute.style.height = '220px';
            banniereHaute.style.margin = '0px';
            habillagePub.style.position = 'relative';
          }
        }
        break;
      case 'huffpost':
        // Bannière haute
        if (adUnitCode === 'banniere_haute') {
          const adsTopBanner = document.querySelector('.ads-topBanner');

          if (bidderCode === 'justpremium') {
            const container = document.querySelector('.container');
            container.style.marginTop = '250px';
            log(`winning bid for ${bidderCode} on ${adUnitCode}`);
          }

          if (bidderCode === 'justpremium' && adsTopBanner) {
            adsTopBanner.style.display = 'none';
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
          }

          if (width === 1800) {
            adsTopBanner.style.display = 'none';
            log(`remove ads-topBanner for ${bidderCode} on ${adUnitCode}`);

            if (bidderCode === 'smart') {
              const leaderBoard = document.querySelector('.leaderboard-flex-placeholder');
              const advertisement = document.querySelector('.advertisement');
              const buzzHead = document.querySelector('.js-cet-unit-buzz_head');
              const buzzBody = document.querySelector('.js-cet-unit-buzz_body');
              log(`custom render for ${bidderCode} on ${adUnitCode}`);

              leaderBoard.style.position = 'absolute';
              advertisement.style.background = 'white';
              buzzHead.style.top = '220px';
              buzzHead.style.maxWidth = '1200px';
              buzzHead.style.margin = 'auto';
              buzzBody.style.position = 'relative';
              buzzBody.style.top = '150px';
            }

            if (
              bidderCode === 'appnexus'
              || bidderCode === 'mediasquare'
              || bidderCode === 'ix'
            ) {
              banniereHaute.style.height = '220px';
              banniereHaute.style.marginTop = '0px';
            }

            if (bidderCode === 'sublime') {
              const ads = document.querySelector('.ads');
              log(`custom render for ${bidderCode} on ${adUnitCode}`);
              ads.style.background = 'none';
            }
          }
        }
        break;
      case 'telerama': case 'teleramaGrille':
        // Bannière haute - arches
        if (adUnitCode === 'banniere_haute' && width === 1800) {
          if (
            bidderCode === 'ix'
            || bidderCode === 'appnexus'
            || bidderCode === 'mediasquare'
            || bidderCode === 'outbrain'
            || bidderCode === 'smartadserver'
          ) {
            banniereHaute.style.height = '220px';
            banniereHaute.style.margin = '0px';
            banniereHaute.style.maxWidth = 'initial';

            if (config.site === 'telerama') {
              const zoningWrapper = document.querySelector('.zoning__wrapper');
              zoningWrapper.style.position = 'relative';
            }

            log(`custom render for ${bidderCode} on ${adUnitCode}`);
          }

          if (config.site === 'teleramaGrille' && bidderCode === 'ix') {
            if (bidderCode === 'ix' || bidderCode === 'mediasquare') {
              const gridChoice = document.querySelector('.tv-grid--header_gridchoice');
              const gridBody = document.querySelector('.tv-grid--body');
              const headerFilters = document.querySelectorAll('.tv-grid--header_filters');

              gridChoice.style.position = 'relative';
              gridChoice.style.background = 'white';
              gridChoice.style.paddingLeft = '10px';
              gridBody.style.background = 'white';
              headerFilters.forEach((element) => {
                element.style.position = 'relative';
              });

              log(`custom render for ${bidderCode} on ${adUnitCode}`);
            }
          }

          if (bidderCode === 'sublime') {
            const ayadsHtml = document.getElementById('ayads-html');
            const promoBanner = document.querySelector('#Nav .sticky_passion');
            const habillagepub = document.getElementById('habillagepub');
            const promoBannerHeight = promoBanner.clientHeight;
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
            ayadsHtml.style.top = promoBannerHeight;
            habillagepub.style.marginTop = promoBannerHeight;
          }
        }
        break;
      case 'lobs':
        // Bannière haute - arches
        if (adUnitCode === 'banniere_haute' && width === 1800) {
          if (
            bidderCode === 'appnexus'
            || bidderCode === 'ix'
            || bidderCode === 'smartadserver'
            || bidderCode === 'outbrain'
          ) {
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
            banniereHaute.style.height = '220px';
          }

          if (bidderCode === 'ix' || bidderCode === 'appnexus' || bidderCode === 'smartadserver') {
            const mainContainer = document.querySelector('#habillagepub .mainContainer');
            const container = document.querySelector('#habillagepub .mainContainer .container');
            const bannerFirstDiv = document.querySelector('#banniere_haute > div');
            mainContainer.style.backgroundColor = 'transparent';
            container.style.padding = '10px';
            bannerFirstDiv.style.padding = '0px';
          }
        }
        break;
      case 'courrier':
        // Bannière haute - arches
        if (adUnitCode === 'banniere_haute' && width === 1800) {
          const siteMain = document.querySelector('#site-main');

          if (
            bidderCode === 'appnexus'
            || bidderCode === 'ix'
            || bidderCode === 'mediasquare'
            || bidderCode === 'smartadserver'
          ) {
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
            banniereHaute.style.height = '220px';
            siteMain.style.position = 'relative';
          }
        }
        break;
      case 'teleobs': case 'conjugaison': case 'coniugazione': case 'conjugacion': case 'conjugacao': case 'theconjugation': case 'konjugation':
        // Bannière haute - arches
        if (adUnitCode === 'banniere_haute' && width === 1800) {
          const gauche = document.querySelector('#gauche');
          const droite = document.querySelector('#droite');

          if (
            bidderCode === 'ix'
            || bidderCode === 'appnexus'
            || bidderCode === 'smartadserver'
            || bidderCode === 'mediasquare'
          ) {
            log(`custom render for ${bidderCode} on ${adUnitCode}`);
            banniereHaute.style.height = '220px';
            banniereHaute.style.position = 'absolute';
            banniereHaute.style.left = '0';
            banniereHaute.style.width = '100%';

            const styles = {
              position: 'relative',
              'margin-top': '240px',
              'z-index': '9',
              background: 'white',
            };

            Object.assign(gauche.style, styles);
            Object.assign(droite.style, styles);
          }

          if (config.site === 'conjugaison' && bidderCode === 'sublime') {
            const main11 = document.querySelector('.main11');

            main11.style.position = 'absolute';
            main11.style.top = '209px';
            main11.style.left = '0';
            main11.style.width = '100%';
            main11.style.paddingLeft = 'calc((100% - 1000px)/2)';
            main11.style.paddingRight = 'calc((100% - 1000px)/2)';
            main11.style.backgroundColor = '#eeeeee';
          }
        }

        // Bannière haute
        if (adUnitCode === 'banniere_haute') {
          if (config.site === 'teleobs' && config.device === 'desktop') {
            banniereHaute.style.marginTop = '42px';
          }
        }
        break;
      default:
    }
  });
};

// Customize an ad render on a specific page or rubric
export const renderAdsOnPage = () => {
  const banniereHaute = document.getElementById('banniere_haute');

  // Target specific website and specific rubric/page
  if (config.site === 'lobs' && window.location.href.includes('nos-podcasts')) {
    if (banniereHaute !== null) {
      log('load custom render for \'Nos Podcasts\' page');
      const podcastsHome = document.querySelector('.podcasts-home');
      const bannHauteIframe = banniereHaute.querySelector('iframe');

      if (bannHauteIframe && bannHauteIframe.offsetWidth === 1800) {
        log('load custom render for banniere_haute on \'Nos Podcasts\' page');
        podcastsHome.style.marginBottom = '0';
      }

      pbjs.onEvent('bidWon', (data) => {
        if (data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          podcastsHome.style.marginBottom = '0';
        }
      });
    }
  }
};

// Handle rendering when slot element appears in the DOM
export const onSlotRenderEnded = (event) => {
  const { slot } = event;
  const slotName = slot.getSlotElementId();

  // Hide cover slot by default on lemonde to avoid whitespace
  if (config.site === 'glmaw' || config.site === 'lemonde') {
    if (slotName === 'cover') {
      const cover = document.querySelector('#cover');
      cover.style.display = 'none';
      log('hide cover slot by default');
    }
  }

  // Remove specified CSS classes from banniere_haute after ad has been loaded
  if (slotName === 'banniere_haute') {
    const banniereHaute = document.querySelector('#banniere_haute');
    const loader = document.querySelector('.loader');

    if (config.site === 'lemonde' || config.site === 'lemondeEN') {
      banniereHaute.style.setProperty('display', 'flex', 'important');
    }

    if (event.isEmpty && (config.site === 'lemonde' || config.site === 'lemondeEN') && loader) {
      banniereHaute.classList.remove(config.unloadedClassName);
      banniereHaute.style.display = 'block';
      loader.style.display = 'none';
      log(`${slotName} -> no ad loaded, doesn't remove loader space`);
    } else {
      banniereHaute.classList.remove(config.loadingClassName);
      banniereHaute.classList.remove(config.unloadedClassName);
      log(`${slotName} -> remove class ${config.loadingClassName} & ${config.unloadedClassName}`);
    }
  }

  if (config.site === 'huffpost') {
    const slotElement = document.querySelector(`#${slotName}`);
    const parent = slotElement.parentElement;
    const placeholder = parent.querySelector('.ads-placeholder');

    // Hide huffpost placeholder when ad is loaded
    if (placeholder && window.getComputedStyle(slotElement).display !== 'none') {
      placeholder.classList.add('hidden');
      log(`hide placeholder on ${slotName}`);
    }

    if (slotName.includes('inread')) {
      const inread = document.querySelector(`#${slotName}`);
      const div = inread.querySelector('div');
      const iframe = div.querySelector('iframe');
      const ampAnimate = inread.querySelector('.amp-animate');

      if (inread && iframe) {
        const iframeWidth = iframe.getAttribute('width');

        if (iframeWidth) {
          if (iframeWidth >= '400px') {
            inread.style.width = '100%';
            log(`set width of ${slotName} slot to 100%`);
          }

          if (iframeWidth >= '0px' && iframeWidth < '400px') {
            inread.style.width = iframeWidth;
            log(`set width of ${slotName} slot to ${iframeWidth}`);
          }
        }

        if (ampAnimate) {
          ampAnimate.style.margin = '0 auto';
        }

        log(`use custom inread render -> ${iframe.offsetWidth}x${iframe.offsetHeight} ${slotName}`);
      }
    }
  }
};

// Handle rendering when ad is loaded in the slot
export const onSlotOnload = (event) => {
  const { slot } = event;
  const slotName = slot.getSlotElementId();

  if (slotName.includes('inread') && config.site === 'lemonde') {
    const inread = document.querySelector(`#${slotName}`);
    const div = inread.querySelector('div');
    const iframe = div.querySelector('iframe');

    if (inread && iframe) {
      const iframeWidth = iframe.getAttribute('width');
      const iframeHeight = iframe.getAttribute('height');

      if (iframeWidth === '300px' && iframeHeight === '250px') {
        div.style.minWidth = 'unset';
      }

      log(`use custom inread render -> ${iframe.offsetWidth}x${iframe.offsetHeight} ${slotName}`);
    }
  }

  // If cover slot loads an ad, display it
  // if (config.site === 'lemonde' || config.site === 'glmaw') {
  //   if (slotName.includes('cover')) {
  //     const lmdBanners = document.querySelector('.lmd-banners');

  //     if (lmdBanners) {
  //       const styles = {
  //         top: '10.5rem',
  //         'z-index': '7',
  //       };

  //       log('adjust lmd-banners style when cover is loaded');
  //       Object.assign(lmdBanners.style, styles);

  //       if (config.device === 'mobile') {
  //         lmdBanners.style.top = '10rem';
  //       }
  //     }
  //   }
  // }
};

// Force style on slot if needed
export const setStyleOnSlot = (element) => {
  const setDisplayAndClass = (display) => {
    element.style.display = display;
    element.classList.add(config.unloadedClassName);
  };

  if (config.site === 'lemonde' || config.site === 'glmaw') {
    // Custom CSS for 'inread_live_sport' parent containers to lazyload the position
    // while still hiding the container when hidden html attribute is set
    if (element.getAttribute('data-format').includes('inread_live_sport')) {
      if (element.parentNode) {
        const parent = element.parentNode;
        parent.style.display = 'block';

        if (parent.hidden) {
          parent.style.visibility = 'hidden';
          parent.style.height = '0';
        }

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
              if (!parent.hidden) {
                parent.style.visibility = 'visible';
                parent.style.height = 'auto';
                log(`${element.id} -> adjust visibility & height & remove mutation observer`);
                observer.disconnect();
              }
            }
          });
        });

        observer.observe(parent, { childList: true, subtree: true });
      }

      setDisplayAndClass('block');
    }

    if (element.getAttribute('data-format').includes('inread')) {
      element.style.overflow = 'hidden';
    }
  }

  if (config.site === 'huffpost') {
    if (element.getAttribute('data-format').includes('inread')) {
      setDisplayAndClass('block');
    }

    if (element.getAttribute('data-format').includes('pave_milieu')) {
      setDisplayAndClass('flex');
    }
  }
};
