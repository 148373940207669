/* global document */
export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/b14d687b-bcaf-4e7f-b6ad-f1e4c2e84ffd',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-telerama.json',
  smartAdCallConfig: {},
  formats: {
    pave_haut: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
    },
    pave_milieu: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
    },
  },
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const teleramaBanner = document.querySelector('#habillagepub #banniere_haute');

    document.styleSheets[0].addRule('.dfp-slot.dfp-loaded::after', 'display:none !important;');
    banner.removeAttribute('class');
    if (teleramaBanner === null) {
      banner.style.cssText = 'position: absolute; z-index: 0; margin:auto; left: 50%; transform: translateX(-50%);';
    } else {
      banner.style.cssText = 'position: absolute; z-index: -1; margin: -220px auto; left: 50%; transform: translateX(-50%);';
    }
    skin.style.cssText = 'position: relative;z-index: 1;margin: 220px auto;max-width: 1000px;';
  }),
  excludeAdUnits: {
    breakpoint: '1024px',
    names: [
      'banniere_haute',
    ],
  },
  appnexus: {
    telerama: {
      publisherId: 1131430,
      banniere_basse: { placementId: 21250161 },
      banniere_haute: { placementId: 21250164 },
      pave_haut: { placementId: 21250167 },
      banniere_milieu: { placementId: 21250166 },
      pave_milieu: { placementId: 21250168 },
      pave_bas: { placementId: 21250170 },
      inread: { placementId: 21250173 },
      inread_top: { placementId: 26485474 },
    },
    refresh: {
      publisherId: 1131430,
      banniere_haute: { placementId: 25029019 },
      banniere_milieu: { placementId: 25029020 },
      banniere_basse: { placementId: 25029018 },
      pave_haut: { placementId: 25029021 },
      pave_milieu: { placementId: 25029022 },
      pave_bas: { placementId: 25029024 },
      inread: { placementId: 25029025 },
      inread_top: { placementId: 26485475 },
    },
  },
  invibes: {
    placementId: 'invibes_telerama',
    placementIdInreadTop: 'invibes_telerama_top',
  },
  indexExchange: {
    telerama: {
      formats: {
        desktop: {
          banniere_haute: '767772',
          pave_haut: '767779',
          inread: '767778',
          inread_top: '767778',
          banniere_milieu: '767777',
          banniere_basse: '767776',
          pave_milieu: '767781',
          pave_bas: '767780',
        },
        tablet: {
          banniere_haute: '767772',
          pave_haut: '767779',
          inread: '767778',
          inread_top: '767778',
          banniere_milieu: '767777',
          banniere_basse: '767776',
          pave_milieu: '767781',
          pave_bas: '767780',
        },
        mobile: {
          banniere_haute: '767772',
          inread: '767778',
          inread_top: '767778',
        },
      },
    },
  },
  justpremium: {
    telerama: {
      banniere_haute: { zone: 151239 },
    },
  },
  mediasquare: {
    telerama: {
      formats: {
        banniere_haute: { code: 'telerama.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'telerama.fr_rg_atf_pave_desktop' },
        inread_top: { code: 'telerama.fr_inread-top_atf_pave_desktop' },
        inread: { code: 'telerama.fr_inread_mtf_pave_desktop' },
        banniere_milieu: { code: 'telerama.fr_rg_mtf_mban_desktop' },
        banniere_basse: { code: 'telerama.fr_rg_btf_mban_desktop' },
        pave_milieu: { code: 'telerama.fr_rg_mtf_pave_desktop' },
        pave_bas: { code: 'telerama.fr_rg_btf_pave_desktop' },
      },
    },
  },
  outbrain: {
    telerama: {
      formats: {
        banniere_haute: 'ATF-3/TRA_banniere_haute',
        pave_haut: 'ATF-3/TRA_pave_haut',
        inread_top: 'ATF-2/TRA_inread_top',
        inread: 'ATF-2/TRA_inread',
        pave_milieu: 'ATF-3/TRA_pave_milieu',
        banniere_milieu: 'ATF-3/TRA_banniere_milieu',
        pave_bas: 'ATF-3/TRA_pave_bas',
        banniere_basse: 'ATF-3/TRA_banniere_basse',
      },
    },
  },
  smart: {
    telerama: {
      siteId: 278834,
      pageId: 1187336,
    },
  },
  sublime: {
    telerama: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31293 },
        },
        mobile: {
          cover: { zoneId: 33650 },
        },
      },
    },
  },
  teads: {
    telerama: {
      formats: {
        banniere_haute: { placementId: 178757, pageId: 164020 },
        pave_haut: { placementId: 178759, pageId: 164022 },
        inread_top: { placementId: 124066, pageId: 114223 },
        inread: { placementId: 124066, pageId: 114223 },
        pave_milieu: { placementId: 178760, pageId: 164023 },
        banniere_milieu: { placementId: 178761, pageId: 164024 },
        pave_bas: { placementId: 178762, pageId: 164025 },
        banniere_basse: { placementId: 178763, pageId: 164026 },
      },
    },
  },
  triplelift: {
    telerama: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Telerama_HB_',
    },
  },
};
