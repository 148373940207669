/* eslint-disable no-restricted-globals */
/* global */
import { config } from './wrapperConfig';
import { updateWrapper, resetWrapper } from './lib/utils';
import { toggleStaging } from './modules/debug';
import { init as initWrapper } from './initWrapper';

initWrapper();
// On route change, ads slots are destroyed and the wrapper is reset
config.wrapperType = 'consent';
updateWrapper();

export {
  toggleStaging as staging,
  config,
  initWrapper,
  resetWrapper,
};
