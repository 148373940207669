export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/464148e4-900d-4b00-a21b-4f9d8704c57f',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  formats: {
    banniere_milieu: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugation-en.json',
  smartAdCallConfig: {},
  amazonTAM: true,
  appnexus: {
    theconjugation: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388089 },
      banniere_haute: { placementId: 19370283 },
      pave_haut: { placementId: 19370298 },
      pave_milieu: { placementId: 19370323 },
      inread: { placementId: 19370319 },
      inread_top: { placementId: 26485456 },
    },
  },
  indexExchange: {
    theconjugation: {
      formats: {
        desktop: {
          banniere_haute: '819143',
          pave_haut: '819134',
          inread: '819135',
          inread_top: '819135',
          banniere_basse: '819136',
          pave_milieu: '819137',
        },
        tablet: {
          banniere_haute: '819143',
          pave_haut: '819134',
          inread: '819135',
          inread_top: '819135',
          banniere_basse: '819136',
          pave_milieu: '819137',
        },
        mobile: {
          banniere_haute: '819143',
          pave_haut: '819134',
          inread: '819135',
          inread_top: '819135',
          banniere_basse: '819136',
          pave_milieu: '819137',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_theconjugation',
    placementIdInreadTop: 'invibes_theconjugation_top',
  },
  mediasquare: {
    theconjugation: {
      formats: {
        banniere_haute: { code: 'the-conjugation.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'the-conjugation.com_rg_atf_pave_desktop' },
        inread_top: { code: 'the-conjugation.com_inread-top_atf_pave_desktop' },
        inread: { code: 'the-conjugation.com_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'the-conjugation.com_rg_btf_mban_desktop' },
        pave_milieu: { code: 'the-conjugation.com_rg_mtf_pave_desktop' },
      },
    },
  },
  outbrain: {
    theconjugation: {
      formats: {
        banniere_haute: 'ATF-3/TheConjugationUK_banniere_haute',
        pave_haut: 'ATF-3/TheConjugationUK_pave_haut',
        pave_milieu: 'ATF-3/TheConjugationUK_pave_milieu',
        inread_top: 'ATF-2/TheConjugationUK_inread_top',
        inread: 'ATF-2/TheConjugationUK_inread',
        banniere_basse: 'ATF-3/TheConjugationUK_banniere_basse',
      },
    },
  },
  sublime: {
    theconjugation: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31892 },
        },
        mobile: {
          cover: { zoneId: 31891 },
        },
      },
    },
  },
  teads: {
    theconjugation: {
      formats: {
        banniere_haute: { placementId: 178795, pageId: 164058 },
        banniere_basse: { placementId: 178794, pageId: 164057 },
        pave_haut: { placementId: 178792, pageId: 164055 },
        pave_milieu: { placementId: 178793, pageId: 164056 },
        inread_top: { placementId: 127456, pageId: 117398 },
        inread: { placementId: 127456, pageId: 117398 },
      },
    },
  },
  smart: {
    theconjugation: {
      siteId: 506178,
      pageId: 1577415,
    },
  },
  triplelift: {
    theconjugation: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_theconjugation_',
    },
  },
};
