/* global document */
export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/dfdca06d-b195-4b0b-94dc-4af4fe251bce',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-courrier.json',
  smartAdCallConfig: {},
  formats: {
    inread: {
      desktop: [
        [300, 250],
        'fluid',
        [2, 2],
        [490, 276],
      ],
      tablet: [
        [300, 250],
        'fluid',
        [2, 2],
      ],
      mobile: [
        [300, 250],
        'fluid',
        [2, 2],
      ],
    },
    inread_top: {
      desktop: [
        [300, 250],
        'fluid',
        [2, 2],
        [490, 276],
      ],
      tablet: [
        [300, 250],
        'fluid',
        [2, 2],
      ],
      mobile: [
        [300, 250],
        'fluid',
        [2, 2],
      ],
    },
  },
  excludeAdUnits: {
    breakpoint: '999px',
    names: [
      'banniere_haute',
      'banniere_basse',
      'banniere_milieu',
      'pave_haut',
      'pave_milieu',
      'pave_bas',
    ],
  },
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const mmPage = document.querySelector('body .mm-page');

    banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); overflow: hidden;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto;';
    if (mmPage) {
      mmPage.style.zIndex = 10000;
    }
  }),
  appnexus: {
    courrier: {
      publisherId: 1131425,
      banniere_basse: { placementId: 21249654 },
      banniere_haute: { placementId: 21249655 },
      pave_haut: { placementId: 21249656 },
      banniere_milieu: { placementId: 21249657 },
      pave_milieu: { placementId: 21249659 },
      pave_bas: { placementId: 21249660 },
      inread: { placementId: 21249691 },
      inread_top: { placementId: 26485412 },
    },
  },
  invibes: {
    placementId: 'invibes_courrierinternational',
    placementIdInreadTop: 'invibes_courrierinternational_top',
  },
  indexExchange: {
    courrier: {
      formats: {
        desktop: {
          banniere_haute: '766721',
          pave_haut: '766701',
          inread: '766719',
          inread_top: '766719',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          pave_milieu: '766715',
          pave_bas: '766722',
        },
        tablet: {
          banniere_haute: '766721',
          pave_haut: '766701',
          inread: '766719',
          inread_top: '766719',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          pave_milieu: '766715',
          pave_bas: '766722',
        },
        mobile: {
          banniere_haute: '766721',
          banniere_milieu: '766717',
          banniere_basse: '766723',
          inread: '766719',
          inread_top: '766719',
        },
      },
    },
  },
  justpremium: {
    courrier: {
      banniere_haute: { zone: 151323 },
    },
  },
  mediasquare: {
    courrier: {
      formats: {
        banniere_haute: { code: 'courrierinternational.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'courrierinternational.com_rg_atf_pave_desktop' },
        inread_top: { code: 'courrierinternational.com_inread-top_atf_pave_desktop' },
        inread: { code: 'courrierinternational.com_inread_mtf_pave_desktop' },
        banniere_milieu: { code: 'courrierinternational.com_rg_mtf_mban_desktop' },
        banniere_basse: { code: 'courrierinternational.com_rg_btf_mban_desktop' },
        pave_milieu: { code: 'courrierinternational.com_rg_mtf_pave_desktop' },
        pave_bas: { code: 'courrierinternational.com_rg_btf_pave_desktop' },
      },
    },
  },
  outbrain: {
    courrier: {
      formats: {
        banniere_haute: 'ATF-3/CI_banniere_haute',
        pave_haut: 'ATF-3/CI_pave_haut',
        inread_top: 'ATF-2/CI_inread_top',
        inread: 'ATF-2/CI_inread',
        pave_milieu: 'ATF-3/CI_pave_milieu',
        banniere_milieu: 'ATF-3/CI_banniere_milieu',
        pave_bas: 'ATF-3/CI_pave_bas',
        banniere_basse: 'ATF-3/CI_banniere_basse',
      },
    },
  },
  smart: {
    courrier: {
      siteId: 278827,
      pageId: 1187317,
    },
  },
  sublime: {
    courrier: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31292 },
        },
        mobile: {
          cover: { zoneId: 33649 },
        },
      },
    },
  },
  teads: {
    courrier: {
      formats: {
        banniere_haute: { placementId: 178777, pageId: 164040 },
        pave_haut: { placementId: 178843, pageId: 164108 },
        inread_top: { placementId: 124063, pageId: 114220 },
        inread: { placementId: 124063, pageId: 114220 },
        pave_milieu: { placementId: 178844, pageId: 164109 },
        banniere_milieu: { placementId: 178780, pageId: 164043 },
        pave_bas: { placementId: 178781, pageId: 164044 },
        banniere_basse: { placementId: 178782, pageId: 164045 },
      },
    },
  },
  triplelift: {
    courrier: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_milieu',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'CourrierInternational_HB_',
    },
  },
};
