/* global window, STAGING_BUILD */
import { config } from './wrapperConfig';
import {
  log,
  loadScript,
  logLoadingTime,
  detectSlots,
} from './lib/utils';
import { isStaging } from './modules/debug';
import * as pubstack from './modules/pubstack';
import * as prebid from './modules/prebid';
import * as gam from './modules/gam';
import * as amazon from './modules/amazon';
import * as grapeshot from './modules/grapeshot';
import { refresh } from './modules/refresh';

const execute = () => {
  const start = window.performance.now();
  pubstack.init();
  prebid.init();
  gam.init();
  amazon.init();

  if (config.site === 'huffpost') {
    amazon.doVideoBid();
  }

  const gamConfigurationStart = window.performance.now();

  gam.configure().then(() => {
    const gamConfigurationEnd = window.performance.now();
    config.loadingTime.gam = Math.round(gamConfigurationEnd - gamConfigurationStart);
    log(`GAM configuration took ${config.loadingTime.gam}ms`);

    grapeshot.load().then(() => {
      prebid.configure();
      const timeout = new Promise((resolve) => setTimeout(resolve, config.timeout));
      const parallelBid = [prebid.sendBid(), amazon.sendBid()];

      Promise.race([Promise.all(parallelBid), timeout]).then(() => {
        gam.display();
        logLoadingTime(start);
        refresh();
      });
    });
  });
};

const executeWithoutOpenAuction = () => {
  pubstack.init();
  gam.init();

  gam.configure().then(() => {
    grapeshot.load().then(() => {
      gam.display();
      refresh();
    });
  });
};

const init = () => {
  if (isStaging('glmaw_staging') === true && STAGING_BUILD === false) {
    log('load staging wrapper script');
    loadScript(config.stagingScript);
  } else {
    log(`initialize wrapper v${config.version}`);
    if (((window.ADS_CONFIG || {}).adUnit || '').length > 0 && detectSlots() === true) {
      if (
        config.adUnit.includes('LM_lemonde_abo')
        || config.adUnit.includes('TRA_telerama_abo')
      ) {
        log('execute wrapper without open auction');
        config.subscriber = true;
        executeWithoutOpenAuction();
      } else {
        execute();
      }
    } else {
      log(
        `There are no '${config.slots}' elements provided by the current page, or ADS_CONFIG.adUnit is undefined, therefore the wrapper cannot be initialized`,
      );
    }
  }
};

export {
  init,
};
