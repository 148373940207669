import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  const { adaptor } = config.indexExchange;
  const siteConfig = config.indexExchange[config.site];
  let placement = '';

  // Check if placement is defined
  if (typeof config.indexExchange[config.site].formats[config.device] !== 'undefined') {
    if (typeof config.indexExchange[config.site].formats[config.device][name] !== 'undefined') {
      placement = config.indexExchange[config.site].formats[config.device][name];
    }
  }

  if (typeof siteConfig !== 'undefined' && placement.length > 0) {
    const siteId = config.indexExchange[config.site].formats[config.device][name];

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        siteId,
      },
    });
  }
};
