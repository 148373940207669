import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  const keywords = {};
  const { adaptor } = config.appnexus;
  const currentSite = config.appnexus[config.site];
  let placement = {};

  // Check if placement is defined
  if (typeof config.appnexus[config.site][name] !== 'undefined') {
    placement = config.appnexus[config.site][name];
  }

  Object.keys(config.targets).forEach((idx) => {
    keywords[idx] = config.targets[idx];
  });

  if (typeof currentSite !== 'undefined' && Object.keys(placement).length > 0) {
    const { placementId } = placement;

    adUnit.bids.push({
      bidder: adaptor,
      params: {
        placementId,
        keywords,
      },
    });
  }
};
