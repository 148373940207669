export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/fe39d2b3-a649-4e0b-9ffe-dd678b8ca58e',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  formats: {
    banniere_milieu: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugacao-pt.json',
  smartAdCallConfig: {},
  appnexus: {
    conjugacao: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388079 },
      banniere_haute: { placementId: 19613623 },
      pave_haut: { placementId: 19613624 },
      pave_milieu: { placementId: 19613626 },
      inread: { placementId: 19613625 },
      inread_top: { placementId: 26485461 },
    },
  },
  indexExchange: {
    conjugacao: {
      formats: {
        desktop: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
        tablet: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
        mobile: {
          banniere_haute: '819128',
          pave_haut: '819119',
          inread: '819120',
          inread_top: '819120',
          banniere_basse: '819121',
          pave_milieu: '819122',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_conjugacaodeverbos',
    placementIdInreadTop: 'invibes_conjugacaodeverbos_top',
  },
  mediasquare: {
    conjugacao: {
      formats: {
        banniere_haute: { code: 'conjugacao-de-verbos.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'conjugacao-de-verbos.com_rg_atf_pave_desktop' },
        inread_top: { code: 'conjugacao-de-verbos.com_inread-top_atf_pave_desktop' },
        inread: { code: 'conjugacao-de-verbos.com_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'conjugacao-de-verbos.com_rg_btf_mban_desktop' },
      },
    },
  },
  outbrain: {
    conjugacao: {
      formats: {
        banniere_haute: 'ATF-3/ConjugacaoPT_banniere_haute',
        pave_haut: 'ATF-3/ConjugacaoPT_pave_haut',
        pave_milieu: 'ATF-3/ConjugacaoPT_pave_milieu',
        inread_top: 'ATF-2/ConjugacaoPT_inread_top',
        inread: 'ATF-2/ConjugacaoPT_inread',
        banniere_basse: 'ATF-3/ConjugacaoPT_banniere_basse',
      },
    },
  },
  smart: {
    conjugacao: {
      siteId: 506181,
      pageId: 1577418,
    },
  },
  // No IDs available yet
  sublime: {
    conjugacao: {
      formats: {
        desktop: {},
        mobile: {},
      },
    },
  },
  teads: {
    conjugacao: {
      formats: {
        banniere_haute: { placementId: 178808, pageId: 164071 },
        banniere_basse: { placementId: 178811, pageId: 164074 },
        pave_haut: { placementId: 178809, pageId: 164072 },
        pave_milieu: { placementId: 178810, pageId: 164073 },
        inread_top: { placementId: 128647, pageId: 118571 },
        inread: { placementId: 128647, pageId: 118571 },
      },
    },
  },
  triplelift: {
    conjugacao: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'Conjug_HB_conjugacao_',
    },
  },
};
