/* global document */
export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/6c9d91b6-476b-4385-b76a-cbdcba750b31',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lobs.json',
  smartAdCallConfig: {},
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const containerLobs = document.querySelector('.container.ObsRubrique');

    if (containerLobs !== null) {
      containerLobs.style.cssText = 'max-width: 1000px; padding: 15px;';
    }
    banner.classList.remove('dfp-unloaded');
    banner.classList.remove('container-auto');
    banner.style.cssText = 'position: absolute; z-index: 0; margin: auto; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; max-width: none !important;';
    skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; width: 1000px;';
  }),
  formats: {
    inimage_seed: {
      desktop: [1, 1],
      tablet: [1, 1],
      mobile: [1, 1],
    },
    banniere_milieu: {
      desktop: [
        [728, 90],
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        [728, 90],
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        [300, 250],
        'fluid',
        [2, 2],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        [300, 250],
        'fluid',
        [2, 2],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        [300, 250],
        'fluid',
        [2, 2],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        [300, 250],
        'fluid',
        [2, 2],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  appnexus: {
    lobs: {
      publisherId: 1131429,
      banniere_basse: { placementId: 21875747 },
      banniere_haute: { placementId: 21875741 },
      pave_haut: { placementId: 21875748 },
      pave_milieu: { placementId: 21875749 },
      pave_bas: { placementId: 21875750 },
      inread: { placementId: 21875751 },
      inread_top: { placementId: 26485432 },
    },
    refresh: {
      publisherId: 1131429,
      banniere_haute: { placementId: 25287196 },
      banniere_basse: { placementId: 25287195 },
      pave_haut: { placementId: 25287198 },
      pave_milieu: { placementId: 25287199 },
      pave_bas: { placementId: 25287197 },
      inread: { placementId: 25287200 },
      inread_top: { placementId: 26485432 },
    },
  },
  indexExchange: {
    lobs: {
      formats: {
        desktop: {
          banniere_haute: '766726',
          pave_haut: '766702',
          inread: '766725',
          inread_top: '766725',
          banniere_basse: '766728',
          pave_milieu: '766724',
          pave_bas: '766727',
        },
        tablet: {
          banniere_haute: '766726',
          pave_haut: '766702',
          inread: '766725',
          inread_top: '766725',
          banniere_basse: '766728',
          pave_milieu: '766724',
          pave_bas: '766727',
        },
        mobile: {
          inread: '766725',
          inread_top: '766725',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_nouvelobs',
    placementIdInreadTop: 'invibes_nouvelobs_top',
  },
  justpremium: {
    lobs: {
      banniere_haute: { zone: 151238 },
    },
  },
  mediasquare: {
    lobs: {
      formats: {
        banniere_haute: { code: 'nouvelobs.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'nouvelobs.com_rg_atf_pave_desktop' },
        inread_top: { code: 'nouvelobs.com_inread-top_atf_pave_desktop' },
        inread: { code: 'nouvelobs.com_inread_mtf_pave_desktop' },
        banniere_basse: { code: 'nouvelobs.com_rg_btf_mban_desktop' },
        pave_milieu: { code: 'nouvelobs.com_rg_mtf_pave_desktop' },
        pave_bas: { code: 'nouvelobs.com_rg_btf_pave_desktop' },
      },
    },
  },
  outbrain: {
    lobs: {
      formats: {
        banniere_haute: 'ATF-3/OBS_banniere_haute',
        pave_haut: 'ATF-3/OBS_pave_haut',
        inread_top: 'ATF-2/OBS_inread_top',
        inread: 'ATF-2/OBS_inread',
        pave_milieu: 'ATF-3/OBS_pave_milieu',
        pave_bas: 'ATF-3/OBS_pave_bas',
        banniere_basse: 'ATF-3/OBS_banniere_basse',
        banniere_milieu: 'ATF-3/OBS_banniere_milieu',
      },
    },
  },
  smart: {
    lobs: {
      siteId: 278833,
      pageId: 1187333,
    },
  },
  sublime: {
    lobs: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31294 },
        },
        mobile: {
          cover: { zoneId: 33651 },
        },
      },
    },
  },
  teads: {
    lobs: {
      formats: {
        banniere_haute: { placementId: 178767, pageId: 164030 },
        pave_haut: { placementId: 178768, pageId: 164031 },
        inread_top: { placementId: 124064, pageId: 114221 },
        inread: { placementId: 124064, pageId: 114221 },
        pave_milieu: { placementId: 178769, pageId: 164032 },
        pave_bas: { placementId: 178770, pageId: 164033 },
        banniere_basse: { placementId: 178771, pageId: 164034 },
        banniere_milieu: { placementId: 178772, pageId: 164035 },
      },
    },
  },
  triplelift: {
    lobs: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'banniere_basse',
          'pave_milieu',
          'pave_bas',
        ],
        mobile: [
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'NouvelObs_HB_',
    },
  },
};
