export default {
  slots: '.dfp-slot',
  // Specific to France Musique
  pubstack: '//boot.pbstck.com/v1/tag/fca8b563-8f27-4fc3-8424-5913f9ab5178',
  refresh: {
    excludedFormats: [],
  },
  // Specific to France Musique
  appnexus: {
    francemusique: {
      publisherId: 2089068,
      banniere_haute: { placementId: 24955404 },
      pave_haut: { placementId: 24955405 },
      pave_bas: { placementId: 24955406 },
      inread: { placementId: 24955407 },
      inread_top: { placementId: 24955407 },
    },
  },
  // Specific to France Musique
  indexExchange: {
    francemusique: {
      formats: {
        desktop: {
          banniere_haute: '808988',
          pave_haut: '808990',
          inread: '808991',
          inread_top: '808991',
          pave_bas: '808994',
        },
        tablet: {
          banniere_haute: '808988',
          pave_haut: '808990',
          inread: '808991',
          inread_top: '808991',
          pave_bas: '808994',
        },
        mobile: {
          banniere_haute: '809010',
          inread: '809013',
          inread_top: '809013',
        },
      },
    },
  },
  // Specific to France Musique
  invibes: {
    placementId: 'invibes_francemusique',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to France Musique
  mediasquare: {
    francemusique: {
      formats: {
        banniere_haute: { code: 'francemusique.radiofrance.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'francemusique.radiofrance.fr_rg_atf_pave_desktop' },
        inread: { code: 'francemusique.radiofrance.fr_inread_mtf_pave_desktop' },
        pave_bas: { code: 'francemusique.radiofrance.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to France Musique
  smart: {
    francemusique: {
      siteId: 505141,
      pageId: 1574603,
    },
  },
  sublime: {
    francemusique: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to France Musique
  teads: {
    francemusique: {
      formats: {
        inread_top: { placementId: 159816, pageId: 145848 },
        inread: { placementId: 159816, pageId: 145848 },
      },
    },
  },
  // Specific to France Musique
  triplelift: {
    francemusique: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'FranceMusique_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
