export default {
  slots: '.dfp-slot',
  pubstack: '//boot.pbstck.com/v1/tag/bf8c220b-f8cb-4bf2-9f86-8b0a5d6d3e77',
  refresh: {
    refreshIntervalDesktop: 20,
    refreshIntervalMobile: 15,
  },
  formats: {
    banniere_milieu: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_basse: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-conjugaison.json',
  smartAdCallConfig: {},
  appnexus: {
    conjugaison: {
      publisherId: 1131429,
      banniere_basse: { placementId: 12392743 },
      banniere_haute: { placementId: 12392744 },
      pave_haut: { placementId: 12392748 },
      pave_milieu: { placementId: 12392749 },
      inread: { placementId: 12392753 },
      inread_top: { placementId: 26485454 },
    },
  },
  indexExchange: {
    conjugaison: {
      formats: {
        desktop: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
        tablet: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
        mobile: {
          banniere_haute: '819124',
          pave_haut: '819125',
          inread: '819126',
          inread_top: '819126',
          pave_milieu: '819127',
        },
      },
    },
  },
  invibes: {
    placementId: 'invibes_nouvelobsconjugaison',
    placementIdInreadTop: 'invibes_nouvelobsconjugaison_top',
  },
  justpremium: {
    conjugaison: {
      banniere_haute: { zone: 151322 },
    },
  },
  mediasquare: {
    conjugaison: {
      formats: {
        banniere_haute: { code: 'la-conjugaison.nouvelobs.com_rg_atf_mban_desktop' },
        pave_haut: { code: 'la-conjugaison.nouvelobs.com_rg_atf_pave_desktop' },
        inread_top: { code: 'la-conjugaison.nouvelobs.com_inread-top_atf_pave_desktop' },
        inread: { code: 'la-conjugaison.nouvelobs.com_inread_mtf_pave_desktop' },
        pave_milieu: { code: 'la-conjugaison.nouvelobs.com_rg_mtf_pave_desktop' },
      },
    },
  },
  outbrain: {
    conjugaison: {
      formats: {
        banniere_haute: 'ATF-3/LaConjugaisonFR_banniere_haute',
        pave_haut: 'ATF-3/LaConjugaisonFR_pave_haut',
        pave_milieu: 'ATF-3/LaConjugaisonFR_pave_milieu',
        inread_top: 'ATF-2/LaConjugaisonFR_inread_top',
        inread: 'ATF-2/LaConjugaisonFR_inread',
      },
    },
  },
  smart: {
    conjugaison: {
      siteId: 502018,
      pageId: 1567018,
    },
  },
  sublime: {
    conjugaison: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31902 },
        },
        mobile: {
          cover: { zoneId: 31901 },
        },
      },
    },
  },
  teads: {
    conjugaison: {
      formats: {
        banniere_haute: { placementId: 178789, pageId: 164052 },
        pave_haut: { placementId: 178790, pageId: 164053 },
        pave_milieu: { placementId: 178791, pageId: 164054 },
        inread_top: { placementId: 127924, pageId: 117825 },
        inread: { placementId: 127924, pageId: 117825 },
      },
    },
  },
  triplelift: {
    conjugaison: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_milieu',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_milieu',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
          'pave_haut',
          'pave_milieu',
        ],
      },
      inventoryCode: 'LaConjugaison_HB_',
    },
  },
};
