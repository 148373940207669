export default {
  slots: '.dfp-slot',
  // Specific to France Culture
  pubstack: '//boot.pbstck.com/v1/tag/48872cf4-f193-4bbc-bf3f-043f1801fc63',
  refresh: {
    excludedFormats: [],
  },
  // Specific to France Culture
  appnexus: {
    franceculture: {
      publisherId: 2089066,
      banniere_haute: { placementId: 24955363 },
      pave_haut: { placementId: 24955364 },
      pave_milieu: { placementId: 21250131 },
      pave_bas: { placementId: 24955365 },
      inread: { placementId: 24955366 },
      inread_top: { placementId: 24955366 },
    },
  },
  // Specific to France Culture
  indexExchange: {
    franceculture: {
      formats: {
        desktop: {
          banniere_haute: '808996',
          pave_haut: '808998',
          inread: '808997',
          inread_top: '808997',
          pave_bas: '809000',
        },
        tablet: {
          banniere_haute: '808996',
          pave_haut: '808998',
          inread: '808997',
          inread_top: '808997',
          pave_bas: '809000',
        },
        mobile: {
          banniere_haute: '809016',
          inread: '809017',
          inread_top: '809017',
        },
      },
    },
  },
  // Specific to France Culture
  invibes: {
    placementId: 'invibes_franceculture',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to France Culture
  mediasquare: {
    franceculture: {
      formats: {
        banniere_haute: { code: 'franceculture.radiofrance.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'franceculture.radiofrance.fr_rg_atf_pave_desktop' },
        inread: { code: 'franceculture.radiofrance.fr_inread_mtf_pave_desktop' },
        pave_bas: { code: 'franceculture.radiofrance.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to France Culture
  smart: {
    franceculture: {
      siteId: 505149,
      pageId: 1574618,
    },
  },
  sublime: {
    franceculture: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to France Culture
  teads: {
    franceculture: {
      formats: {
        inread_top: { placementId: 159818, pageId: 145850 },
        inread: { placementId: 159818, pageId: 145850 },
      },
    },
  },
  // Specific to France Culture
  triplelift: {
    franceculture: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'FranceCulture_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
