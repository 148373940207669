export default {
  slots: '.dfp-slot',
  // Specific to Fip
  pubstack: '//boot.pbstck.com/v1/tag/b902aa5b-9d16-40bf-aab4-1615fb8ce3ed',
  refresh: {
    excludedFormats: [],
  },
  // Specific to Fip
  appnexus: {
    fip: {
      publisherId: 2089065,
      banniere_haute: { placementId: 24955374 },
      pave_haut: { placementId: 24955375 },
      pave_bas: { placementId: 24955376 },
      inread: { placementId: 24955377 },
      inread_top: { placementId: 24955377 },
    },
  },
  // Specific to Fip
  indexExchange: {
    fip: {
      formats: {
        desktop: {
          banniere_haute: '809001',
          pave_haut: '809003',
          inread: '809002',
          inread_top: '809002',
          pave_bas: '809004',
        },
        tablet: {
          banniere_haute: '809001',
          pave_haut: '809003',
          inread: '809002',
          inread_top: '809002',
          pave_bas: '809004',
        },
        mobile: {
          banniere_haute: '809019',
          inread: '809018',
          inread_top: '809018',
        },
      },
    },
  },
  // Specific to Fip
  invibes: {
    placementId: 'invibes_fip',
    endpoint: 'https://bid4.videostep.com/Bid/VideoAdContent',
  },
  // Specific to Fip
  mediasquare: {
    fip: {
      formats: {
        banniere_haute: { code: 'fip.radiofrance.fr_rg_atf_mban_desktop' },
        pave_haut: { code: 'fip.radiofrance.fr_rg_atf_pave_desktop' },
        inread: { code: 'fip.radiofrance.fr_inread_mtf_pave_desktop' },
        pave_bas: { code: 'fip.radiofrance.fr_rg_btf_pave_desktop' },
      },
    },
  },
  // Specific to Fip
  smart: {
    fip: {
      siteId: 505150,
      pageId: 1574620,
    },
  },
  sublime: {
    fip: {
      formats: {
        desktop: {
          banniere_haute: { zoneId: 31387 },
        },
        mobile: {
          cover: { zoneId: 33903 },
        },
      },
    },
  },
  // Specific to Fip
  teads: {
    fip: {
      formats: {
        inread_top: { placementId: 159819, pageId: 145851 },
        inread: { placementId: 159819, pageId: 145851 },
      },
    },
  },
  // Specific to Fip
  triplelift: {
    fip: {
      formats: {
        desktop: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        tablet: [
          'banniere_haute',
          'pave_haut',
          'inread',
          'inread_top',
          'pave_bas',
        ],
        mobile: [
          'banniere_haute',
          'inread',
          'inread_top',
        ],
      },
      inventoryCode: 'fip_HB_Desktop_',
    },
  },
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/v2/mapping/smart-consentless-lemonde.json',
  smartAdCallConfig: {},
};
