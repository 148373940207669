/* global navigator */
/**
 * Code adapted from ismobilejs - https://github.com/kaimallea/isMobile
 * @author: Kai Mallea (kmallea@gmail.com)
 * @license: http://creativecommons.org/publicdomain/zero/1.0/
 */

const applePhone = /iPhone/i;
const appleIpod = /iPod/i;
const appleTablet = /iPad/i;
const androidPhone = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i; // Match 'Android' AND 'Mobile'
const androidTablet = /Android/i;
const amazonPhone = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i;
const amazonTablet = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i;
const windowsPhone = /Windows Phone/i;
const windowsTablet = /(?=.*\bWindows\b)(?=.*\bARM\b)/i; // Match 'Windows' AND 'ARM'
const otherBlackberry = /BlackBerry/i;
const otherBlackberry10 = /BB10/i;
const otherOpera = /Opera Mini/i;
const otherChrome = /(CriOS|Chrome)(?=.*\bMobile\b)/i;
const otherFirefox = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i; // Match 'Firefox' AND 'Mobile'

const sevenInchReg = new RegExp('(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)i');

const match = (regex, userAgent) => regex.test(userAgent);

let ua = navigator.userAgent;

// Facebook mobile app's integrated browser adds a bunch of strings that
// match everything. Strip it out if it exists.
let tmp = ua.split('[FBAN');
if (typeof tmp[1] !== 'undefined') {
  ({ ua } = tmp);
}

// Twitter mobile app's integrated browser on iPad adds a "Twitter for
// iPhone" string. Same probable happens on other tablet platforms.
// This will confuse detection so strip it out if it exists.
tmp = ua.split('Twitter');
if (typeof tmp[1] !== 'undefined') {
  ({ ua } = tmp);
}

const apple = {
  phone: match(applePhone, ua),
  ipod: match(appleIpod, ua),
  tablet: !match(applePhone, ua) && match(appleTablet, ua),
  device: match(applePhone, ua) || match(appleIpod, ua) || match(appleTablet, ua),
};

const android = {
  phone: match(amazonPhone, ua) || match(androidPhone, ua),
  tablet: (
    !match(amazonPhone, ua)
    && !match(androidPhone, ua)
    && (match(amazonTablet, ua) || match(androidTablet, ua))
  ),
  device: (
    match(amazonPhone, ua)
    || match(amazonTablet, ua)
    || match(androidPhone, ua)
    || match(androidTablet, ua)
  ),
};

const windows = {
  phone: match(windowsPhone, ua),
  tablet: match(windowsTablet, ua),
  device: match(windowsPhone, ua) || match(windowsTablet, ua),
};

const other = {
  blackberry: match(otherBlackberry, ua),
  blackberry10: match(otherBlackberry10, ua),
  opera: match(otherOpera, ua),
  firefox: match(otherFirefox, ua),
  chrome: match(otherChrome, ua),
  device: (
    match(otherBlackberry, ua)
    || match(otherBlackberry10, ua)
    || match(otherOpera, ua)
    || match(otherFirefox, ua)
    || match(otherChrome, ua)
  ),
};

const sevenInch = match(sevenInchReg, ua);

export const any = apple.device || android.device || windows.device || other.device || sevenInch;

export const phone = apple.phone || android.phone || windows.phone;

export const tablet = apple.tablet || android.tablet || windows.tablet;
