import { config } from '../../wrapperConfig';

export const buildBid = (slot, name, adUnit) => {
  // Temporarily disabling invibes on courrier stories
  if (!adUnit.pubstack.adUnitPath.includes('CI_courrier_int/stories/stories')) {
    const placement = config.invibes.formats.indexOf(name);

    if (typeof config.invibes !== 'undefined' && placement > -1) {
      const { adaptor, endpoint } = config.invibes;
      let placementId = '';

      if (name === 'inread') {
        placementId = config.invibes.placementId;
      } else {
        placementId = config.invibes.placementIdInreadTop;
      }

      adUnit.bids.push({
        bidder: adaptor,
        params: {
          placementId,
          customEndpoint: endpoint,
        },
      });
    }
  }
};
